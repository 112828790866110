import {
    AddNode,
    SetSiteFlow,
    AddSite,
    AddSiteFailure,
    AddSiteSuccess,
    FetchSiteHeirarchy,
    FetchSiteHeirarchyFailure,
    FetchSiteHeirarchySuccess,
    HideSite,
    RemoveNewNode,
    RemoveSelectedNode,
    RemoveSite,
    SelectNode,
    ShowSite,
    SetSelectedSiteDevice,
    RemoveSelectedSiteDevice,
    AddSiteClicked,
    NodeClicked,
    SetAllocateClicked,
    FetchAllocateDevices,
    FetchAllocateDevicesSuccess,
    FetchAllocateDevicesFailure,
    AllocateDevice,
    AllocateDeviceSuccess,
    AllocateDeviceFailure,
    UnAllocateDevice,
    UnAllocateDeviceSuccess,
    UnAllocateDeviceFailure,
    SetInstalledClicked,
    FetchInstallDevices,
    FetchInstallDevicesSuccess,
    FetchInstallDevicesFailure,
    InstallDevice,
    InstallDeviceFailure,
    InstallDeviceSuccess,
    UnInstallDevice,
    UnInstallDeviceSuccess,
    UnInstallDeviceFailure,
    FetchSiteRhs,
    FetchSiteRhsSuccess,
    FetchSiteRhsFailure,
} from 'redux/types/siteActionModel';
import { Thing } from 'shared/model/DeviceModel';
import {
    AddNewSite,
    AllocateDeviceData,
    AllocateDevices,
    InstallDeviceData,
    InstallDevices,
    SiteHeirarchyModel,
    SiteRhsData,
    UnAllocateDevices,
    UnInstallDevices,
} from 'shared/model/SiteModel';
import * as actionTypes from '../actionTypes';
import { FailurePayload } from '../types';

export const fetchSiteHeirarchyRequest = (id: number): FetchSiteHeirarchy => ({
    type: actionTypes.FETCH_SITE_HEIRARCHY,
    payload: { id },
});

export const fetchSiteHeirarchyRequestSuccess = (payload: SiteHeirarchyModel): FetchSiteHeirarchySuccess => ({
    type: actionTypes.FETCH_SITE_HEIRARCHY_SUCCESS,
    payload,
});

export const fetchSiteHeirarchyRequestSuccessFailure = (payload: FailurePayload): FetchSiteHeirarchyFailure => ({
    type: actionTypes.FETCH_SITE_HEIRARCHY_FAILURE,
    payload,
});

export const addNewNode = (id: any): AddNode => ({
    type: actionTypes.ADD_NEW_NODE,
    payload: { id },
});

export const setSiteFlow = (payload: any): SetSiteFlow => ({
    type: actionTypes.SET_SITE_FLOW,
    payload,
});

export const addNewSite = (payload: AddNewSite): AddSite => ({
    type: actionTypes.ADD_NEW_SITE,
    payload,
});

export const addNewSiteSuccess = (): AddSiteSuccess => ({
    type: actionTypes.ADD_NEW_SITE_SUCCESS,
});

export const addNewSiteFailure = (payload: FailurePayload): AddSiteFailure => ({
    type: actionTypes.ADD_NEW_SITE_FAILURE,
    payload,
});

export const removeNewNode = (): RemoveNewNode => ({
    type: actionTypes.REMOVE_NEW_NODE,
});

export const removeSelectedNode = (): RemoveSelectedNode => ({
    type: actionTypes.REMOVE_SELECTED_NODE,
});

export const onNodeSelect = (id: any): SelectNode => ({
    type: actionTypes.SELECT_NODE,
    payload: { id },
});

export const removeSite = (id: any, org_id: any): RemoveSite => ({
    type: actionTypes.REMOVE_SITE,
    payload: { id, org_id },
});

export const hideSites = (id: any): HideSite => ({
    type: actionTypes.HIDE_SITE,
    payload: { id },
});

export const showSites = (id: any): ShowSite => ({
    type: actionTypes.SHOW_SITE,
    payload: { id },
});

export const setSelectedSiteDevice = (thing: Thing): SetSelectedSiteDevice => ({
    type: actionTypes.SET_SITE_SELECTED_DEVICE,
    payload: { thing },
});

export const removeSelectedSiteDevice = (): RemoveSelectedSiteDevice => ({
    type: actionTypes.REMOVE_SITE_SELECTED_DEVICE,
});
export const addSiteClicked = (payload: boolean): AddSiteClicked => ({
    type: actionTypes.ADD_SITE_CLICKED,
    payload,
});
export const nodeClicked = (payload: boolean): NodeClicked => ({
    type: actionTypes.NODE_CLICKED,
    payload,
});
export const setAllocateClicked = (payload: boolean): SetAllocateClicked => ({
    type: actionTypes.SET_ALLOCATE_CLICKED,
    payload,
});
export const fetchAllocateDevicesDataRequest = (oid: number, id: number): FetchAllocateDevices => ({
    type: actionTypes.FETCH_ALLOCATE_DEVICES,
    payload: { oid, id },
});

export const fetchAllocateDevicesDataRequestSuccess = (payload: AllocateDeviceData): FetchAllocateDevicesSuccess => ({
    type: actionTypes.FETCH_ALLOCATE_DEVICES_SUCCESS,
    payload,
});

export const fetchAllocateDevicesDataRequestFailure = (payload: FailurePayload): FetchAllocateDevicesFailure => ({
    type: actionTypes.FETCH_ALLOCATE_DEVICES_FAILURE,
    payload,
});
export const allocateDevice = (payload: AllocateDevices): AllocateDevice => ({
    type: actionTypes.ALLOCATE_DEVICE,
    payload,
});

export const allocateDeviceSuccess = (payload: AllocateDeviceData): AllocateDeviceSuccess => ({
    type: actionTypes.ALLOCATE_DEVICE_SUCCESS,
    payload,
});

export const allocateDeviceFailure = (payload: FailurePayload): AllocateDeviceFailure => ({
    type: actionTypes.ALLOCATE_DEVICE_FAILURE,
    payload,
});
export const unAllocateDevice = (payload: UnAllocateDevices): UnAllocateDevice => ({
    type: actionTypes.UN_ALLOCATE_DEVICE,
    payload,
});

export const unAllocateDeviceSuccess = (payload: AllocateDeviceData): UnAllocateDeviceSuccess => ({
    type: actionTypes.UN_ALLOCATE_DEVICE_SUCCESS,
    payload,
});

export const unAllocateDeviceFailure = (payload: FailurePayload): UnAllocateDeviceFailure => ({
    type: actionTypes.UN_ALLOCATE_DEVICE_FAILURE,
    payload,
});
export const setInstalledClicked = (payload: boolean): SetInstalledClicked => ({
    type: actionTypes.SET_INSTALLED_CLICKED,
    payload,
});
export const fetchInstallDevicesDataRequest = (oid: number, id: number): FetchInstallDevices => ({
    type: actionTypes.FETCH_INSTALL_DEVICES,
    payload: { oid, id },
});

export const fetchInstallDevicesDataRequestSuccess = (payload: InstallDeviceData): FetchInstallDevicesSuccess => ({
    type: actionTypes.FETCH_INSTALL_DEVICES_SUCCESS,
    payload,
});

export const fetchInstallDevicesDataRequestFailure = (payload: FailurePayload): FetchInstallDevicesFailure => ({
    type: actionTypes.FETCH_INSTALL_DEVICES_FAILURE,
    payload,
});
export const installDevice = (payload: InstallDevices): InstallDevice => ({
    type: actionTypes.INSTALL_DEVICE,
    payload,
});

export const installDeviceSuccess = (payload: InstallDeviceData): InstallDeviceSuccess => ({
    type: actionTypes.INSTALL_DEVICE_SUCCESS,
    payload,
});

export const installDeviceFailure = (payload: FailurePayload): InstallDeviceFailure => ({
    type: actionTypes.INSTALL_DEVICE_FAILURE,
    payload,
});
export const unInstallDevice = (payload: UnInstallDevices): UnInstallDevice => ({
    type: actionTypes.UNINSTALL_DEVICE,
    payload,
});

export const unInstallDeviceSuccess = (payload: InstallDeviceData): UnInstallDeviceSuccess => ({
    type: actionTypes.UNINSTALL_DEVICE_SUCCESS,
    payload,
});

export const unInstallDeviceFailure = (payload: FailurePayload): UnInstallDeviceFailure => ({
    type: actionTypes.UNINSTALL_DEVICE_FAILURE,
    payload,
});

export const fetchSiteRhs = (device_list: string[]): FetchSiteRhs => ({
    type: actionTypes.FETCH_SITE_RHS,
    payload: { device_list },
});

export const fetchSiteRhsSuccess = (payload: SiteRhsData): FetchSiteRhsSuccess => ({
    type: actionTypes.FETCH_SITE_RHS_SUCCESS,
    payload,
});

export const fetchSiteRhsSuccessFailure = (payload: FailurePayload): FetchSiteRhsFailure => ({
    type: actionTypes.FETCH_SITE_RHS_FAILURE,
    payload,
});
