import React from 'react';
import { t } from 'i18next';
import { Switch, Route } from 'react-router-dom';
import { URLValidationHOC } from 'shared/Components/hoc/URLValidationHOC';
import { URL_Type } from 'shared/utils/Constants';
import { FORGOT_PASSWORD, INVITED_USER, LOGIN, RESET_PASSWORD } from './urlConstant';

// Containers
const TheLayout = React.lazy(() => import('./TheLayout'));

// Pages
const Login = React.lazy(() => import('views/auth/login/Login'));
const ForgotPassord = React.lazy(() => import('views/auth/forgot-password/ForgotPassword'));
const ResetPassord = React.lazy(() => import('views/auth/forgot-password/ResetPassword'));
const InvitedUser = React.lazy(() => import('views/auth/invited-user/InvitedUser'));

const InvitedUserHOC = URLValidationHOC(InvitedUser, t('set_password'), t('enter_new_password'));
const ResetPassordHOC = URLValidationHOC(ResetPassord, t('change_password'), t('enter_new_password'));

const BaseRoute = () => {
    return (
        <Switch>
            <Route exact path={LOGIN} render={(props: any) => <Login {...props} />} />
            <Route exact path={FORGOT_PASSWORD} render={(props: any) => <ForgotPassord {...props} />} />
            <Route
                exact
                path={RESET_PASSWORD}
                render={(props: any) => <ResetPassordHOC type={URL_Type.forgot_password} {...props} />}
            />
            <Route
                exact
                path={INVITED_USER}
                render={(props: any) => <InvitedUserHOC type={URL_Type.invitation} {...props} />}
            />
            <Route path="/" render={(props: any) => <TheLayout {...props} />} />
        </Switch>
    );
};

export default BaseRoute;
