import { SET_TOAST_MESSAGE } from 'redux/actionTypes';
import { SetToastMessageAction, ToastState } from 'redux/types/commonModel';

const initialState: ToastState | undefined = {
    message: '',
    variant: undefined,
};

export const toastStateReducer = (state = initialState, action: SetToastMessageAction): ToastState => {
    if (action.type === SET_TOAST_MESSAGE) {
        return {
            ...state,
            message: action.message,
            variant: action.variant,
        };
    } else {
        return state;
    }
};
