import { getSortedData } from 'shared/service/CommonService';
import * as actionTypes from '../actionTypes';

import { DashboardActions, DashboardState } from '../types';

const initialState: DashboardState = {
    isFetched: false,
    isLoading: false,
    aqi_overview: undefined,
    device_health_alerts: undefined,
    error: null,
    isPerformaceFetched: false,
    isPerformaceLoading: false,
    performance_data: undefined,
    performance_error: null,
    is_performace_graph_loading: false,
    performance_graph_data: undefined,
    performance_graph_error: null,
    is_performace_bar_loading: false,
    performance_bar_data: undefined,
    performance_bar_error: null,
    is_performace_bar_fetched: false,
    isFetchFromCard: false,
};

export const dashboardReducer = (state = initialState, action: DashboardActions): DashboardState => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                aqi_overview: undefined,
                device_health_alerts: undefined,
                error: null,
                is_performace_bar_fetched: false,
                isPerformaceLoading: true,
                isPerformaceFetched: false,
                performance_data: undefined,
                performance_error: null,
            };
        case actionTypes.FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetched: true,
                aqi_overview: action.payload.dashboard.aqi_overview,
                device_health_alerts: action.payload.dashboard.device_health_alerts,
                error: null,
                isPerformaceLoading: false,
                isPerformaceFetched: true,
                performance_data: action.payload.dashboard.performance_benefits_data,
                performance_error: null,
            };
        case actionTypes.FETCH_DASHBOARD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFetched: true,
                aqi_overview: undefined,
                device_health_alerts: undefined,
                error: action.payload.error,
                isPerformaceLoading: false,
                isPerformaceFetched: true,
                performance_data: undefined,
                performance_error: action.payload.error,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST:
            return {
                ...state,
                is_performace_graph_loading: true,
                performance_graph_data: undefined,
                performance_graph_error: null,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_SUCCESS:
            return {
                ...state,
                is_performace_graph_loading: false,
                performance_graph_data: action.payload,
                performance_graph_error: null,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_FAILURE:
            return {
                ...state,
                is_performace_graph_loading: false,
                performance_graph_data: undefined,
                performance_graph_error: action.payload.error,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST:
            return {
                ...state,
                is_performace_bar_loading: true,
                performance_bar_data: undefined,
                performance_bar_error: null,
                is_performace_bar_fetched: false,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_SUCCESS:
            return {
                ...state,
                is_performace_bar_loading: false,
                performance_bar_data: { bars: getSortedData(action.payload.bars) },
                performance_bar_error: null,
                is_performace_bar_fetched: true,
            };
        case actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_FAILURE:
            return {
                ...state,
                is_performace_bar_loading: false,
                performance_bar_data: undefined,
                performance_bar_error: action.payload.error,
                is_performace_bar_fetched: true,
            };
        case actionTypes.DASHBOARD_PERFORMANCE_GRAPH_LOADING:
            return {
                ...state,
                is_performace_graph_loading: action.payload,
            };
        case actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD:
            return {
                ...state,
                isFetchFromCard: true,
            };
        case actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD_SUCCESS:
            return {
                ...state,
                aqi_overview: action.payload.dashboard.aqi_overview,
                device_health_alerts: action.payload.dashboard.device_health_alerts,
                performance_data: action.payload.dashboard.performance_benefits_data,
                isFetchFromCard: false,
            };
        case actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD_FAILURE:
            return {
                ...state,
                isFetchFromCard: false,
            };
        default:
            return {
                ...state,
            };
    }
};
