import * as actionTypes from '../actionTypes';
import {
    FetchOrganizationFailure,
    FetchOrganizationRequest,
    FetchOrganizationSuccess,
    FetchOrganizationSuccessPayload,
    SaveOrganizationRequest,
    SaveOrganizationSuccess,
    SaveOrganizationFailure,
    UpdateOrganizationRequest,
    DeleteOrganizationRequest,
    FailurePayload,
    SetSelectedOrganization,
    RemoveSelectedOrganization,
    OrganizationRequestById,
    OrganizationRequestByName,
    OrganizationRequestByIdSuccess,
    RemoveAllOrganization,
} from 'redux/types';
import { AddOrganizationModel, OrganizationModel } from 'shared/model';
import { ORG_TYPES } from 'shared/utils/Constants';

export const fetchOrganizationRequest = (org_type = ORG_TYPES.CUSTOMER): FetchOrganizationRequest => ({
    type: actionTypes.FETCH_ORG_REQUEST,
    payload: { org_type },
});

export const fetchOrganizationSuccess = (payload: FetchOrganizationSuccessPayload): FetchOrganizationSuccess => ({
    type: actionTypes.FETCH_ORGANIZATION_SUCCESS,
    payload,
});

export const fetchOrganizationFailure = (payload: FailurePayload): FetchOrganizationFailure => ({
    type: actionTypes.FETCH_ORGANIZATION_FAILURE,
    payload,
});

export const saveOrganizationRequest = (organization: AddOrganizationModel): SaveOrganizationRequest => ({
    type: actionTypes.SAVE_ORGANIZATION_REQUEST,
    payload: organization,
});

export const saveOrganizationSuccess = (): SaveOrganizationSuccess => ({
    type: actionTypes.SAVE_ORGANIZATION_SUCCESS,
});

export const saveOrganizationFailure = (payload: FailurePayload): SaveOrganizationFailure => ({
    type: actionTypes.SAVE_ORGANIZATION_FAILURE,
    payload,
});

export const updateOrganizationRequest = (organization: AddOrganizationModel): UpdateOrganizationRequest => ({
    type: actionTypes.UPDATE_ORGANIZATION_REQUEST,
    payload: organization,
});

export const deleteOrganizationRequest = (id: number): DeleteOrganizationRequest => ({
    type: actionTypes.DELETE_ORGANIZATION_REQUEST,
    payload: { id },
});

export const setSelectedOrganizationAction = (id: number): SetSelectedOrganization => ({
    type: actionTypes.SET_SELECTED_ORGANIZATION,
    payload: { id },
});

export const removeSelectedOrganizationAction = (): RemoveSelectedOrganization => ({
    type: actionTypes.REMOVE_SELECTED_ORGANIZATION,
});

export const getOrganizationByName = (
    name: string,
    additional_info = true,
    org_type?: ORG_TYPES,
): OrganizationRequestByName => ({
    type: actionTypes.FETCH_ORG_BY_NAME,
    payload: { name, additional_info, org_type },
});

export const getOrganizationById = (payload: { id: number }): OrganizationRequestById => ({
    type: actionTypes.FETCH_ORGANIZATION_BY_ID_REQUEST,
    payload,
});

export const getOrganizationByIdSuccess = (payload: { org: OrganizationModel }): OrganizationRequestByIdSuccess => ({
    type: actionTypes.FETCH_ORGANIZATION_BY_ID_REQUEST_SUCCESS,
    payload,
});

export const removeAllOrg = (): RemoveAllOrganization => ({
    type: actionTypes.REMOVE_ALL_ORGANIZATIONS,
});
