import { all, call, Effect, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import {
    addNewSiteFailure,
    addNewSiteSuccess,
    allocateDeviceFailure,
    allocateDeviceSuccess,
    fetchAllocateDevicesDataRequestFailure,
    fetchAllocateDevicesDataRequestSuccess,
    fetchInstallDevicesDataRequestFailure,
    fetchInstallDevicesDataRequestSuccess,
    fetchSiteHeirarchyRequest,
    fetchSiteHeirarchyRequestSuccess,
    fetchSiteHeirarchyRequestSuccessFailure,
    fetchSiteRhsSuccess,
    fetchSiteRhsSuccessFailure,
    installDeviceFailure,
    installDeviceSuccess,
    removeNewNode,
    removeSelectedNode,
    unAllocateDeviceFailure,
    unAllocateDeviceSuccess,
    unInstallDeviceFailure,
    unInstallDeviceSuccess,
} from 'redux/actions/siteActions';
import {
    AllocateDeviceData,
    InstallDeviceData,
    NodeElement,
    SiteHeirarchyModel,
    SiteRhsData,
} from 'shared/model/SiteModel';
import {
    addSiteAPI,
    allocateDeviceAPI,
    getAllocateDevicesAPI,
    getInstallDevicesAPI,
    getSiteHeirarchyAPI,
    getSiteRhsApi,
    installDeviceAPI,
    removeSiteAPI,
    unAllocateDeviceAPI,
    unInstallDeviceAPI,
} from 'api/siteHeirarchyApi';
import { t } from 'i18next';
import { setToastAction } from 'redux/actions';
import store from 'redux/store/configStore';
import { ToastVariant } from 'shared/utils/Constants';
import { changeRightSidebarAction } from 'redux/actions/SidebarAction';
import {
    deviceAllocateSuccessMesaage,
    deviceInstallSuccessMesaage,
    deviceUnAllocateSuccessMesaage,
    deviceUninstallSuccessMesaage,
    deviceUninstallWithUnallocateSuccessMesaage,
} from 'shared/service/CommonService';

/*
  Worker Saga: Fired on FETCH_SITE_HEIRARCHY action
*/
function* fetchHeirarchySaga(action: Effect) {
    try {
        const response: SiteHeirarchyModel = yield call(getSiteHeirarchyAPI, action.payload.id);
        yield put(fetchSiteHeirarchyRequestSuccess(response));
    } catch (e: any) {
        yield put(
            fetchSiteHeirarchyRequestSuccessFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on ADD_NEW_SITE action
*/
function* addSiteSaga(action: Effect) {
    try {
        yield call(addSiteAPI, action.payload);
        yield put(addNewSiteSuccess());
        store.dispatch(changeRightSidebarAction(false));
        store.dispatch(removeSelectedNode());
        store.dispatch(removeNewNode());
        yield put(fetchSiteHeirarchyRequest(action.payload.org_id));
    } catch (e: any) {
        yield put(
            addNewSiteFailure({
                error: e.message,
            }),
        );
    }
}

/*
Worker Saga: Fired on REMOVE_SITE action
*/
function* removeSiteSaga(action: Effect) {
    try {
        const response: NodeElement = yield call(removeSiteAPI, action.payload.id, action.payload.org_id);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, t('site_deleted_successfully')));
        store.dispatch(changeRightSidebarAction(false));
        yield put(fetchSiteHeirarchyRequest(response.org_id));
    } catch (e: any) {
        yield put(
            addNewSiteFailure({
                error: e.message,
            }),
        );
    }
}

function* fetchAlloateDevicesaga(action: Effect) {
    try {
        const response: AllocateDeviceData = yield call(getAllocateDevicesAPI, action?.payload?.oid, action.payload.id);
        yield put(fetchAllocateDevicesDataRequestSuccess(response));
    } catch (e: any) {
        yield put(
            fetchAllocateDevicesDataRequestFailure({
                error: e.message,
            }),
        );
    }
}

function* allocateDeviceSaga(action: Effect) {
    try {
        const response: AllocateDeviceData = yield call(allocateDeviceAPI, action.payload);
        yield put(allocateDeviceSuccess(response));
        store.dispatch(fetchSiteHeirarchyRequest(action.payload.org_id));
        store.dispatch(setToastAction(ToastVariant.SUCCESS, deviceAllocateSuccessMesaage));
    } catch (e: any) {
        yield put(
            allocateDeviceFailure({
                error: e.message,
            }),
        );
    }
}
function* unAllocateDeviceSaga(action: Effect) {
    try {
        const response: AllocateDeviceData = yield call(unAllocateDeviceAPI, action.payload);
        yield put(unAllocateDeviceSuccess(response));
        store.dispatch(fetchSiteHeirarchyRequest(action.payload.org_id));
        store.dispatch(setToastAction(ToastVariant.SUCCESS, deviceUnAllocateSuccessMesaage));
    } catch (e: any) {
        yield put(
            unAllocateDeviceFailure({
                error: e.message,
            }),
        );
    }
}

function* fetchInstallDevicesaga(action: Effect) {
    try {
        const response: InstallDeviceData = yield call(getInstallDevicesAPI, action?.payload?.oid, action.payload.id);
        yield put(fetchInstallDevicesDataRequestSuccess(response));
    } catch (e: any) {
        yield put(
            fetchInstallDevicesDataRequestFailure({
                error: e.message,
            }),
        );
    }
}

function* installDeviceSaga(action: Effect) {
    try {
        const response: InstallDeviceData = yield call(installDeviceAPI, action.payload);
        yield put(installDeviceSuccess(response));
        store.dispatch(setToastAction(ToastVariant.SUCCESS, deviceInstallSuccessMesaage));
        store.dispatch(fetchSiteHeirarchyRequest(action.payload.org_id));
    } catch (e: any) {
        yield put(
            installDeviceFailure({
                error: e.message,
            }),
        );
    }
}

function* unInstallDeviceSaga(action: Effect) {
    try {
        const response: InstallDeviceData = yield call(unInstallDeviceAPI, action.payload);
        yield put(unInstallDeviceSuccess(response));
        store.dispatch(fetchSiteHeirarchyRequest(action.payload.org_id));
        store.dispatch(
            setToastAction(
                ToastVariant.SUCCESS,
                action.payload.unallocate_device
                    ? deviceUninstallWithUnallocateSuccessMesaage
                    : deviceUninstallSuccessMesaage,
            ),
        );
    } catch (e: any) {
        yield put(
            unInstallDeviceFailure({
                error: e.message,
            }),
        );
    }
}

function* fetcSiteRhsSaga(action: Effect) {
    try {
        const response: SiteRhsData = yield call(getSiteRhsApi, action.payload);
        yield put(fetchSiteRhsSuccess(response));
    } catch (e: any) {
        yield put(
            fetchSiteRhsSuccessFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_SITE_HEIRARCHY`   action.
*/
function* siteHeirarchySaga() {
    yield all([
        takeLatest(actionTypes.FETCH_SITE_HEIRARCHY, fetchHeirarchySaga),
        takeLatest(actionTypes.ADD_NEW_SITE, addSiteSaga),
        takeLatest(actionTypes.REMOVE_SITE, removeSiteSaga),
        takeLatest(actionTypes.FETCH_ALLOCATE_DEVICES, fetchAlloateDevicesaga),
        takeLatest(actionTypes.ALLOCATE_DEVICE, allocateDeviceSaga),
        takeLatest(actionTypes.UN_ALLOCATE_DEVICE, unAllocateDeviceSaga),
        takeLatest(actionTypes.FETCH_INSTALL_DEVICES, fetchInstallDevicesaga),
        takeLatest(actionTypes.INSTALL_DEVICE, installDeviceSaga),
        takeLatest(actionTypes.UNINSTALL_DEVICE, unInstallDeviceSaga),
        takeLatest(actionTypes.FETCH_SITE_RHS, fetcSiteRhsSaga),
    ]);
}

export default siteHeirarchySaga;
