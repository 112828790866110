import { SHOW_HIDE_RHSPANEL, SHOW_RHS_PANEL_TOGGLER } from 'redux/actionTypes/sidebarActionTypes';
import { ShowHideRHSPanel, ShowRHSToggler } from 'redux/types/sidebarActionModel';

export const changeRightSidebarAction = (show: boolean | 'responsive'): ShowHideRHSPanel => {
    return {
        type: SHOW_HIDE_RHSPANEL,
        payload: { show },
    };
};

export const setRightSidebarToggler = (show: boolean): ShowRHSToggler => {
    return {
        type: SHOW_RHS_PANEL_TOGGLER,
        payload: { show },
    };
};
