import React from 'react';
import { useSelector } from 'react-redux';
import { themeSelector } from 'redux/selectors/userSelector';
import { getText1 } from 'shared/service/CommonService';

type SpinnerLoaderType = {
    customClass?: string;
};

const SpinnerLoader = ({ customClass }: SpinnerLoaderType) => {
    const theme = useSelector(themeSelector);
    return (
        <output className={`spinner-border ${getText1(theme)} text-white ${customClass || 'custom-loader'} `}>
            <span className="sr-only">Loading...</span>
        </output>
    );
};

export default SpinnerLoader;
