import {
    DASHBOARD,
    DASHBOARD_BAR_PERFORMANCE,
    DASHBOARD_PERFORMANCE,
    DASHBOARD_PERFORMANCE_RANGE,
} from 'containers/urlConstant';
import { http_get } from './BaseURLAxios';

export const getDashboardData = async () => {
    return await http_get(DASHBOARD).then((res: any) => {
        return res;
    });
};

export const getDashboardPerformaceGraphData = async (type: string, timestamp: string) => {
    return await http_get(DASHBOARD_PERFORMANCE + '/' + type + '/' + timestamp).then((res: any) => {
        return res;
    });
};

export const getDashboardPerformaceBarData = async () => {
    return await http_get(DASHBOARD_BAR_PERFORMANCE).then((res: any) => {
        return res;
    });
};

export const getRanges = async () => {
    return await http_get(DASHBOARD_PERFORMANCE_RANGE).then((res: any) => {
        return res;
    });
};
