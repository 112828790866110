import { PollutantRange, SharedActions, SharedState } from 'redux/types';
import { convertCToF, sortAqiRange, sortVirusOrProductivityRange } from 'shared/service/CommonService';
import { PollutantEnum, PollutantSymbolByName, TemperatureEnum } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';

const initialState: SharedState = {
    isLoading: false,
    isFetched: false,
    error: undefined,
    org: [],
    lifecycle_state: undefined,
    location: undefined,
    aqi_range: [],
    pollutant_range: [],
    virus_range: [],
    isRangeLoading: false,
    isRangeError: undefined,
    productivity_risk_range: [],
    range_fetched: false,
};

export const sharedReducer = (state = initialState, action: SharedActions): SharedState => {
    switch (action.type) {
        case actionTypes.FETCH_FILTER_VALUE:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                error: undefined,
                org: [],
                lifecycle_state: undefined,
                location: undefined,
            };
        case actionTypes.FETCH_FILTER_VALUE_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                error: undefined,
                org: action.payload.org,
                lifecycle_state: action.payload.lifecycle_state,
                location: action.payload.location,
            };
        case actionTypes.FETCH_FILTER_VALUE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFetched: true,
                error: action.payload.error,
            };
        case actionTypes.FETCH_RANGES_VALUE:
            return {
                ...state,
                isRangeLoading: true,
                isRangeError: undefined,
                aqi_range: [],
                pollutant_range: [],
                virus_range: [],
                productivity_risk_range: [],
                range_fetched: false,
            };
        case actionTypes.FETCH_RANGES_VALUE_SUCCESS:
            if (action.payload.temperaturePreference === TemperatureEnum.FAHRENHEIT) {
                action.payload.pollutant_range.forEach((value: PollutantRange) => {
                    if (value.pollutant_name === PollutantEnum.temp) {
                        value.bp_high = Math.round(convertCToF(value.bp_high));
                        value.bp_low = Math.round(convertCToF(value.bp_low));
                        value.unit_of_measure = PollutantSymbolByName.DEGREE_FAHRENHEIT;
                    }
                });
            }
            return {
                ...state,
                isRangeLoading: false,
                isRangeError: undefined,
                aqi_range: [...sortAqiRange(action?.payload?.aqi_range)],
                pollutant_range: [...action.payload.pollutant_range],
                virus_range: [...sortVirusOrProductivityRange(action?.payload?.virus_risk_range)],
                productivity_risk_range: [...sortVirusOrProductivityRange(action?.payload?.productivity_risk_range)],
                range_fetched: true,
            };
        case actionTypes.FETCH_RANGES_VALUE_FAILURE:
            return {
                ...state,
                isRangeLoading: false,
                isRangeError: action.payload.error,
                range_fetched: true,
            };
        default:
            return {
                ...state,
            };
    }
};
