import BaseAPI from './BaseURLAxios';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { addTokenToRequest, exemptedUrls, handleResponseError } from './AxiosService';
import { BASE_URL } from './urlConstants';
import store from 'redux/store/configStore';
import { setToastAction } from 'redux/actions';
import { ToastVariant } from 'shared/utils/Constants';
import { t } from 'i18next';
export default axios.create({
    baseURL: BASE_URL,
});

BaseAPI.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Request comes here before leaving UI, check for any value or add headers
        return addTokenToRequest(config);
    },
    (error) => {
        return Promise.reject(error);
    },
);

BaseAPI.interceptors.response.use(
    (config: AxiosResponse) => {
        // Response come here from backend, check for any value or headers
        return config;
    },
    (error: any) => {
        if (error.config.url && exemptedUrls(error.config.url)) {
            return Promise.reject(error);
        }
        return handleResponseError(error);
    },
);

export const http_get = async (url: string, config?: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        BaseAPI.get(url, config)
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject('Something went wrong');
                }
            })
            .catch((err: AxiosError) => {
                errorDetails(err);
                reject(err);
            });
    });
};

export const http_post = async (url: string, data: any, config?: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        BaseAPI.post(url, data, config)
            .then((res: AxiosResponse) => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res.data);
                } else {
                    reject('Something went wrong');
                }
            })
            .catch((err: AxiosError) => {
                errorDetails(err);
                reject(err);
            });
    });
};

export const http_delete = async (url: string, config?: InternalAxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        BaseAPI.delete(url, config)
            .then((res: AxiosResponse) => {
                resolve(res.data);
            })
            .catch((err: AxiosError) => {
                errorDetails(err);
                reject(err);
            });
    });
};

export const http_put = async (url: string, body: any, config?: InternalAxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        BaseAPI.put(url, body, config)
            .then((res: AxiosResponse) => {
                resolve(res.data);
            })
            .catch((err: AxiosError) => {
                errorDetails(err);
                reject(err);
            });
    });
};
function errorDetails(err: AxiosError<any>) {
    store.dispatch(
        setToastAction(ToastVariant.DANGER, err?.response?.data ? err.response.data : t('something_went_wrong')),
    );
}
