export const FETCH_ORG_REQUEST = 'FETCH_ORG_REQUEST';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE';
export const SAVE_ORGANIZATION_REQUEST = 'SAVE_ORGANIZATION_REQUEST';
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS';
export const SAVE_ORGANIZATION_FAILURE = 'SAVE_ORGANIZATION_FAILURE';
export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';
export const REMOVE_SELECTED_ORGANIZATION = 'REMOVE_SELECTED_ORGANIZATION';
export const FETCH_ORG_BY_NAME = 'FETCH_ORG_BY_NAME';
export const FETCH_ORGANIZATION_BY_ID_REQUEST = 'FETCH_ORGANIZATION_BY_ID_REQUEST';
export const FETCH_ORGANIZATION_BY_ID_REQUEST_SUCCESS = 'FETCH_ORGANIZATION_BY_ID_REQUEST_SUCCESS';
export const REMOVE_ALL_ORGANIZATIONS = 'REMOVE_ALL_ORGANIZATIONS';
