export const FETCH_MODEL_NAME = 'FETCH_MODEL_NAME';
export const FETCH_MODEL_NAME_SUCCESS = 'FETCH_MODEL_NAME_SUCCESS';
export const FETCH_MODEL_NAME_FAILURE = 'FETCH_MODEL_NAME_FAILURE';
export const ADD_FIRMWARE_REQUEST = 'ADD_FIRMWARE_REQUEST';
export const ADD_FIRMWARE_SUCCESS = 'ADD_FIRMWARE_SUCCESS';
export const ADD_FIRMWARE_FAILURE = 'ADD_FIRMWARE_FAILURE';
export const UPDATE_FIRMWARE_REQUEST = 'UPDATE_FIRMWARE_REQUEST';
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const UPDATE_FIRMWARE_FAILURE = 'UPDATE_FIRMWARE_FAILURE';
export const DELETE_FIRMWARE_REQUEST = 'DELETE_FIRMWARE_REQUEST';
export const DELETE_FIRMWARE_SUCCESS = 'DELETE_FIRMWARE_SUCCESS';
export const DELETE_FIRMWARE_FAILURE = 'DELETE_FIRMWARE_FAILURE';
export const SET_ADD_FIRMWARE_SUCCESS = 'SET_ADD_FIRMWARE_SUCCESS';
export const FETCH_FIRMWARE_LIST = 'FETCH_FIRMWARE_LIST';
export const FETCH_FIRMWARE_LIST_SUCCESS = 'FETCH_FIRMWARE_LIST_SUCCESS';
export const FETCH_FIRMWARE_LIST_FAILURE = 'FETCH_FIRMWARE_LIST_FAILURE';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE';
export const UPDATE_FIRMWARE_LIST = 'UPDATE_FIRMWARE_LIST';
export const SET_VIEW_FIRMWARE_OPEN = 'SET_VIEW_FIRMWARE_OPEN';
export const SET_ADD_FIRMWARE_OPEN = 'SET_ADD_FIRMWARE_OPEN';
export const SET_EDIT_FIRMWARE_OPEN = 'SET_EDIT_FIRMWARE_OPEN';
export const CLEAR_FIRMWARE_DATA = 'CLEAR_FIRMWARE_DATA';
export const FETCH_SPECIFIC_FIRMWARE_DATA = 'FETCH_SPECIFIC_FIRMWARE_DATA';
export const FETCH_SPECIFIC_FIRMWARE_DATA_SUCCESS = 'FETCH_SPECIFIC_FIRMWARE_DATA_SUCCESS';
export const FETCH_SPECIFIC_FIRMWARE_DATA_FAILURE = 'FETCH_SPECIFIC_FIRMWARE_DATA_FAILURE';
export const FETCH_FIRMWARE_LATEST_VERSION = 'FETCH_FIRMWARE_LATEST_VERSION';
export const FETCH_FIRMWARE_LATEST_VERSION_SUCCESS = 'FETCH_FIRMWARE_LATEST_VERSION_SUCCESS';
export const FETCH_FIRMWARE_LATEST_VERSION_FAILURE = 'FETCH_FIRMWARE_LATEST_VERSION_FAILURE';