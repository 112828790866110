import { Performance_Benefits } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';
import {
    FetchDashboardRequest,
    FetchDashboardSuccess,
    FetchDashboardSuccessPayload,
    FetchDashboardFailure,
    FailurePayload,
    FetchDashboardPerformanceGraphFailure,
    FetchDashboardPerformanceGraphRequest,
    FetchDashboardPerformanceGraphSuccess,
    IDASHBOARDGRAPH,
    FetchDashboardPerformanceBarFailure,
    FetchDashboardPerformanceBarRequest,
    FetchDashboardPerformanceBarSuccess,
    IBARSUCCESSRESPONSE,
    PerformanceBenefitGraphLoading,
    FetchDashboardFromCard,
    FetchDashboardFromCardSuccess,
    FetchDashboarFromCarddFailure,
} from '../types';

export const fetchDashboardRequest = (): FetchDashboardRequest => ({
    type: actionTypes.FETCH_DASHBOARD_REQUEST,
});

export const fetchDashboardSuccess = (payload: FetchDashboardSuccessPayload): FetchDashboardSuccess => ({
    type: actionTypes.FETCH_DASHBOARD_SUCCESS,
    payload,
});

export const fetchDashboardFailure = (payload: FailurePayload): FetchDashboardFailure => ({
    type: actionTypes.FETCH_DASHBOARD_FAILURE,
    payload,
});

export const fetchDashboardPerformanceGraphRequest = (payload: {
    type: Performance_Benefits;
    timestamp: string;
}): FetchDashboardPerformanceGraphRequest => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST,
    payload,
});

export const fetchDashboardPerformanceGraphSuccess = (
    payload: IDASHBOARDGRAPH,
): FetchDashboardPerformanceGraphSuccess => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_SUCCESS,
    payload,
});

export const fetchDashboardPerformanceGraphFailure = (
    payload: FailurePayload,
): FetchDashboardPerformanceGraphFailure => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_FAILURE,
    payload,
});

export const fetchDashboardPerformanceBarRequest = (): FetchDashboardPerformanceBarRequest => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST,
});

export const fetchDashboardPerformanceBarSuccess = (
    payload: IBARSUCCESSRESPONSE,
): FetchDashboardPerformanceBarSuccess => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_SUCCESS,
    payload,
});

export const fetchDashboardPerformanceBarFailure = (payload: FailurePayload): FetchDashboardPerformanceBarFailure => ({
    type: actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_FAILURE,
    payload,
});

export const dashboardperformanceLoader = (payload: boolean): PerformanceBenefitGraphLoading => ({
    type: actionTypes.DASHBOARD_PERFORMANCE_GRAPH_LOADING,
    payload,
});
export const fetchDashboardFromCard = (): FetchDashboardFromCard => ({
    type: actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD,
});

export const fetchDashboardFromCardSuccess = (
    payload: FetchDashboardSuccessPayload,
): FetchDashboardFromCardSuccess => ({
    type: actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD_SUCCESS,
    payload,
});

export const fetchDashboardFromCarddFailure = (payload: FailurePayload): FetchDashboarFromCarddFailure => ({
    type: actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD_FAILURE,
    payload,
});
