import {
    IAQIKeyValueType,
    RemoveSelectedAQICategory,
    RemoveSelectedAQIKeyValue,
    RemoveSelectedLifecycleState,
    RemoveSelectedLocation,
    RemoveSelectedOrg,
    SaveDeviceFilter,
    SetSelectedAQICategory,
    SetSelectedAQIKey,
    SetSelectedAQIKeyValue,
    SetSelectedLifecycleState,
    SetSelectedLocation,
    SetSelectedOrg,
    RemoveDeviceFilter,
    SetDeviceStatus,
    RemoveDeviceStatus,
    SetOldFilterDevice,
    SetDeviceTab,
    RemoveAlertType,
    SetAlertType,
    SetDeviceType,
    RemoveDeviceType,
    SetSelectedModel,
    RemoveSelectedModel,
    CancelClicked,
    ClearSelectionClickedFromPanel,
    IsAnyFilterSelected,
    IsAlertOrIAQFilterSelected,
    IsApplyClick,
    IsFilterAppliedFromDashboard,
} from 'redux/types/deviceFilterActionModel';
import { OrganizationModel } from 'shared/model';
import { AlertType, DeviceTabs, DEVICE_STATUS, KeyWordsValue, StatusEnum, DeviceType } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';

export const setSelectedOrg = (payload: OrganizationModel): SetSelectedOrg => ({
    type: actionTypes.SET_SELECTED_ORG_FILTER,
    payload,
});

export const removeSelectedOrg = (): RemoveSelectedOrg => ({
    type: actionTypes.REMOVE_SELECTED_ORG_FILTER,
});

export const setSelectedLifecycleState = (payload: string): SetSelectedLifecycleState => ({
    type: actionTypes.SET_SELECTED_LIFECYCLE_STATE,
    payload,
});

export const removeSelectedLifecycleState = (): RemoveSelectedLifecycleState => ({
    type: actionTypes.REMOVE_SELECTED_LIFECYCLE_STATE,
});

export const setSelectedLocation = (payload: string): SetSelectedLocation => ({
    type: actionTypes.SET_SELECTED_LOCATION,
    payload,
});

export const removeSelectedLocation = (): RemoveSelectedLocation => ({
    type: actionTypes.REMOVE_SELECTED_LOCATION,
});

export const setSelectedAQIKeyValue = (value: number, category: IAQIKeyValueType): SetSelectedAQIKeyValue => ({
    type: actionTypes.SET_SELECTED_AQI_KEY_VALUE,
    payload: { value, type: category.type },
});

export const setSelectedAQIKey = (key: typeof KeyWordsValue, category: IAQIKeyValueType): SetSelectedAQIKey => ({
    type: actionTypes.SET_SELECTED_AQI_KEY,
    payload: { key, type: category.type },
});

export const removeSelectedAQIKeyValue = (val: IAQIKeyValueType): RemoveSelectedAQIKeyValue => ({
    type: actionTypes.REMOVE_SELECTED_AQI_KEY_VALUE,
    payload: { type: val.type },
});

export const setSelectedAQICategory = (value: StatusEnum, category: IAQIKeyValueType): SetSelectedAQICategory => ({
    type: actionTypes.SET_SELECTED_AQI_CATEGORY,
    payload: { value, type: category.type },
});

export const removeSelectedAQICategory = (val: IAQIKeyValueType): RemoveSelectedAQICategory => ({
    type: actionTypes.REMOVE_SELECTED_AQI_CATEGORY,
    payload: { type: val.type },
});

export const saveDeviceFilter = (): SaveDeviceFilter => ({
    type: actionTypes.SAVE_FILTER,
});

export const removeDeviceFilter = (): RemoveDeviceFilter => ({
    type: actionTypes.RESET_DEVICE_FILTER,
});

export const setDeviceStatus = (payload: DEVICE_STATUS): SetDeviceStatus => ({
    type: actionTypes.SET_DEVICE_STATUS,
    payload,
});

export const removeDeviceStatus = (): RemoveDeviceStatus => ({
    type: actionTypes.REMOVE_DEVICE_STATUS,
});

export const setOldDeviceFilter = (): SetOldFilterDevice => ({
    type: actionTypes.SET_OLD_DEVICE_FILTER,
});

export const setSelectedDeviceTab = (payload: DeviceTabs): SetDeviceTab => ({
    type: actionTypes.SET_DEVICE_TAB,
    payload,
});

export const setAlertType = (payload: AlertType): SetAlertType => ({
    type: actionTypes.SET_ALERT_TYPE,
    payload,
});

export const setDeviceType = (payload: DeviceType): SetDeviceType => ({
    type: actionTypes.SET_DEVICE_TYPE,
    payload,
});

export const removeAlertType = (): RemoveAlertType => ({
    type: actionTypes.REMOVE_ALERT_TYPE,
});

export const removeDeviceType = (): RemoveDeviceType => ({
    type: actionTypes.REMOVE_DEVICE_TYPE,
});

export const setSelectedModel = (value: string): SetSelectedModel => ({
    type: actionTypes.SET_SELECTED_MODEL,
    payload: value,
});

export const removeSelectedModel = (): RemoveSelectedModel => ({
    type: actionTypes.REMOVE_SELECTED_MODEL,
});

export const clearSelectionClickedFromPanel = (val: boolean): ClearSelectionClickedFromPanel => ({
    type: actionTypes.CLEAR_SELECTION_CLICKED_FROM_PANEL,
    payload: val,
});

export const cancelClicked = (): CancelClicked => ({
    type: actionTypes.CANCEL_CLICKED,
});

export const isAnyFilterSelected = (value: boolean): IsAnyFilterSelected => ({
    type: actionTypes.IS_ANY_FILTER_SELECTED,
    payload: value,
});

export const isAlertOrIAQFilterSelected = (value: boolean): IsAlertOrIAQFilterSelected => ({
    type: actionTypes.IS_ALERT_OR_IAQ_FILTER_SELECTED,
    payload: value,
});
export const isApplyClick = (value: boolean): IsApplyClick => ({
    type: actionTypes.IS_APPLY_CLICK,
    payload: value,
});
export const isFilterAppliedFromDashboard = (value: boolean): IsFilterAppliedFromDashboard => ({
    type: actionTypes.IS_FILTER_APPLIED_FROM_DASHBOARD,
    payload: value,
});
