import { all, call, Effect, put, takeLatest, delay, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';

import {
    changeDeviceConfirmationSuccess,
    ChangeDeviceConfirmationSuccessFailure,
    changeDeviceModeFailure,
    changeDeviceModeSuccess,
    fetchCommandsTableDataFailure,
    fetchCommandsTableDataSuccess,
} from 'redux/actions/deviceCommandsActions';
import { devicecommandtable, deviceMode, deviceModeConfirmation } from 'api/deviceAPI';
import { getdeviceconfirmationdata, getdevicemodedata } from 'redux/selectors/deviceCommandSelector';
import {
    CommandsDataSuccess,
    Deviceconfirmdataapi,
    Devicemodedataapi,
    SecondAPiResponse,
} from 'redux/types/deviceCommandModel';
import { Regen, selfclean } from 'shared/utils/Constants';

/*
  Worker Saga: Fired on CHANGE_DEVICE_MODE action
*/

function* sendDeviceMode(action: Effect) {
    try {
        const response: Devicemodedataapi = yield call(deviceMode, action.payload);
        yield put(
            changeDeviceModeSuccess({
                devicemodedata: response,
                command_name: action.payload.action == Regen.REGEN ? selfclean.SELFCLEAN : action.payload.action,
            }),
        );

        const deviceList: Devicemodedataapi = yield select(getdevicemodedata);
        const deviceLength = deviceList?.devicemodedata?.device_command_id_dict;
        const deviceConfirmationData: string[] = deviceLength
            ? yield call(deviceConfirmation, action.payload, response)
            : '';
    } catch (e: any) {
        yield put(
            changeDeviceModeFailure({
                error: e.message,
            }),
        );
    }
}

function* deviceConfirmation(payload: any, response: any) {
    let elapsedTime = 0;
    const timeLimit = 10 * 1000; // 10 seconds in milliseconds
    let deviceconfirmationdatacheck: any = [];
    const deviceData: Devicemodedataapi = yield select(getdevicemodedata);
    const offlineDevicesLength = deviceData?.devicemodedata?.offline_devices.length;
    while (elapsedTime < timeLimit) {
        try {
            const deviceconfirmationdata: SecondAPiResponse = yield select(getdeviceconfirmationdata);

            const body = {
                command: payload?.command,
                device_command_id_dict: response?.device_command_id_dict,
            };
            /* Call your API here using payload and response for first time and only if confirmed_count and total_count are same*/
            const apiresponse: Deviceconfirmdataapi =
                deviceconfirmationdata?.deviceconfirmationdata === undefined ||
                deviceconfirmationdata?.deviceconfirmationdata?.confirmed_count !=
                    deviceconfirmationdata?.deviceconfirmationdata?.total_count
                    ? yield call(deviceModeConfirmation, body)
                    : deviceconfirmationdata?.deviceconfirmationdata;

            deviceconfirmationdatacheck = apiresponse;
            yield put(
                changeDeviceConfirmationSuccess({
                    deviceconfirmationdata: apiresponse,
                    islastcall: elapsedTime === 9000,
                    // islastcall: lastcall,
                }),
            );

            // Increment the elapsed time by 1 seconds
            elapsedTime += 1000;

            yield delay(1000); // Wait for 1 seconds before the next iteration
        } catch (e: any) {
            // Handle error if the second API call fails
            yield put(
                ChangeDeviceConfirmationSuccessFailure({
                    error: e,
                }),
            );
        }
    }

    const sendFailure: string[] =
        (deviceconfirmationdatacheck?.confirmed_count === 0 && deviceconfirmationdatacheck?.total_count >= 1) ||
        (deviceconfirmationdatacheck?.confirmed_count === 0 &&
            deviceconfirmationdatacheck?.total_count === 0 &&
            offlineDevicesLength >= 1)
            ? yield put(
                  ChangeDeviceConfirmationSuccessFailure({
                      error: 'error',
                  }),
              )
            : '';
}
function* fetchRemoteCommandsTableData(action: Effect) {
    try {
        const response: CommandsDataSuccess = yield call(devicecommandtable, action?.payload?.data);
        yield put(
            fetchCommandsTableDataSuccess({
                data: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchCommandsTableDataFailure({
                error: e.message,
            }),
        );
    }
}
/*
  Starts worker saga on latest dispatched `CHANGE_DEVICE_MODE` action.
*/
function* deviceCommandSage() {
    yield all([takeLatest(actionTypes.CHANGE_DEVICE_MODE, sendDeviceMode)]);
    yield all([takeLatest(actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA, fetchRemoteCommandsTableData)]);
}

export default deviceCommandSage;
