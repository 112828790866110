import { AddOrganizationModel } from 'shared/model';
import { ORG_TYPES } from 'shared/utils/Constants';
import { http_delete, http_get, http_post, http_put } from './BaseURLAxios';
import { ORGANIZATION } from './urlConstants';

export const getOrganizations = async (org_type = ORG_TYPES.CUSTOMER) => {
    return http_get(ORGANIZATION + '?org_type=' + org_type).then((res: any) => {
        return res;
    });
};

export const addOrganization = async (body: AddOrganizationModel) => {
    return http_post(ORGANIZATION, body).then((res: any) => {
        return res;
    });
};

export const updateOrganization = async (body: AddOrganizationModel) => {
    return http_put(ORGANIZATION, body).then((res: any) => {
        return res;
    });
};

export const deleteOrganization = async (id: number) => {
    return http_delete(ORGANIZATION + '/' + id).then((res: any) => {
        return res;
    });
};

export const searchOrganization = async (payload: { name: string; additional_info: boolean; org_type?: ORG_TYPES }) => {
    return http_get(
        ORGANIZATION +
            '/search/' +
            payload.name +
            '?additional_info=' +
            payload.additional_info +
            (payload.org_type ? '&org_type=' + payload.org_type : ''),
    ).then((res: any) => {
        return res;
    });
};

export const getOrganizationByIdAPI = async (id: number) => {
    return http_get(ORGANIZATION + '/' + id).then((res: any) => {
        return res;
    });
};
