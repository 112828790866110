import { URL_Type } from 'shared/utils/Constants';
import { http_get, http_post } from './BaseURLAxios';
import { LOGIN_URL, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL } from './urlConstants';

export const onLoginAPI = async (body: any) => {
    return http_post(LOGIN_URL, body).then((res: any) => {
        return res;
    });
};

export const onForgotPasswordAPI = async (email: string) => {
    return http_get(FORGOT_PASSWORD_URL + '/' + email).then((res: any) => {
        return res;
    });
};

export const onResetPasswordAPI = async (
    body: { new_pwd: string; is_verified: boolean; url: string; type: URL_Type },
    token: string,
) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return http_post(RESET_PASSWORD_URL, body, config).then((res: any) => {
        return res;
    });
};
