export const USER_REQUEST = 'USER_REQUEST';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const USER_REQUEST_FAILURE = 'USER_REQUEST_FAILURE';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_REQUEST_SUCCESS = 'USER_UPDATE_REQUEST_SUCCESS';
export const USER_UPDATE_REQUEST_FAILURE = 'USER_UPDATE_REQUEST_FAILURE';
export const ALL_USER_REQUEST = 'ALL_USER_REQUEST';
export const ALL_USER_REQUEST_SUCCESS = 'ALL_USER_REQUEST_SUCCESS';
export const ALL_USER_REQUEST_FAILURE = 'ALL_USER_REQUEST_FAILURE';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_REQUEST_SUCCESS = 'INVITE_USER_REQUEST_SUCCESS';
export const INVITE_USER_REQUEST_FAILURE = 'INVITE_USER_REQUEST_FAILURE';
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const RESEND_INVITE_REQUEST = 'RESEND_INVITE_REQUEST';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILURE = 'GET_USER_ROLES_FAILURE';
export const REMOVE_USER = 'REMOVE_USER';
export const SWITCH_THEME = 'SWITCH_THEME';
export const SWITCH_THEME_SUCCESS_FAILURE = 'SWITCH_THEME_SUCCESS_FAILURE';
export const SWITCH_TEMPERATURE = 'SWITCH_TEMPERATURE';
export const SWITCH_TEMPERATURE_SUCCESS_FAILURE = 'SWITCH_TEMPERATURE_SUCCESS_FAILURE';
