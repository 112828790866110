import { LoginRequest, LoginRequestFailure, LoginRequestSuccess, LoginResponse } from 'redux/types/authActionModel';
import * as actionTypes from '../actionTypes';
import { FailurePayload } from '../types';

export const loginRequest = (payload: { email: string; password: string; }): LoginRequest => ({
    type: actionTypes.LOGIN_REQUEST,
    payload,
});

export const loginRequestSuccess = (payload: LoginResponse): LoginRequestSuccess => ({
    type: actionTypes.LOGIN_REQUEST_SUCCESS,
    payload,
});

export const loginRequestFailure = (payload: FailurePayload): LoginRequestFailure => ({
    type: actionTypes.LOGIN_REQUEST_FAILURE,
    payload,
});
