import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './scss/style.scss';
import store from './redux/store/configStore';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Toaster from 'shared/Components/toastr/Toaster';
import BaseRoute from 'containers/BaseRoute';
import { ReactFlowProvider } from 'react-flow-renderer';
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

class App extends Component {
    render() {
        return (
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <HashRouter>
                            <React.Suspense fallback={loading}>
                                <ReactFlowProvider>
                                    <BaseRoute />
                                    <Toaster />
                                </ReactFlowProvider>
                            </React.Suspense>
                        </HashRouter>
                    </Provider>
                </I18nextProvider>
            </React.StrictMode>
        );
    }
}

export default App;
