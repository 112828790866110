import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const getLoding = (state: AppState) => state.userReducer.isLoading;
const getUser = (state: AppState) => state.userReducer.user;
const getError = (state: AppState) => state.userReducer.error;

export const getLodingSelector = createSelector(getLoding, (isLoading) => isLoading);

export const getUserSelector = createSelector(getUser, (user) => user);

export const getErrorSelector = createSelector(getError, (error) => error);

const getUpdateLoding = (state: AppState) => state.userReducer.isUpdateLoading;
const getUpdateError = (state: AppState) => state.userReducer.updateError;
const getUpdatefetch = (state: AppState) => state.userReducer.isUpdateFetched;

export const getUpdateLodingSelector = createSelector(getUpdateLoding, (isUpdateLoading) => isUpdateLoading);

export const getUpdateErrorSelector = createSelector(getUpdateError, (updateError) => updateError);

export const getUpdatefetchSelector = createSelector(getUpdatefetch, (isUpdateFetched) => isUpdateFetched);

const getAllUsersLoading = (state: AppState) => state.userReducer.isUsersLoading;
const getAllUsersError = (state: AppState) => state.userReducer.usersError;
const getAllUsers = (state: AppState) => state.userReducer.users;

export const getAllUsersLodingSelector = createSelector(getAllUsersLoading, (isUsersLoading) => isUsersLoading);
export const getAllUsersErrorSelector = createSelector(getAllUsersError, (usersError) => usersError);
export const getAllUsersSelector = createSelector(getAllUsers, (users) => users);

const getInviteUsersLoading = (state: AppState) => state.userReducer.inviteUserLoading;
const getInviteUserError = (state: AppState) => state.userReducer.inviteUserError;
const getInvitelUserFetched = (state: AppState) => state.userReducer.inviteUserFetched;

export const getInviteUserLodingSelector = createSelector(
    getInviteUsersLoading,
    (inviteUserLoading) => inviteUserLoading,
);
export const getInviteUserErrorSelector = createSelector(getInviteUserError, (inviteUserLoading) => inviteUserLoading);
export const getInviteFetchedUserSelector = createSelector(
    getInvitelUserFetched,
    (inviteUserFetched) => inviteUserFetched,
);

const getUserRoles = (state: AppState) => state.userReducer.user?.roles;
const getUserRolesLoading = (state: AppState) => state.userReducer.userRoleLoading;
const getUserRolesFetched = (state: AppState) => state.userReducer.userRoleFetched;

export const getUserRolesSelector = createSelector(getUserRoles, (roles) => roles);
export const getUserRolesLoadingSelector = createSelector(getUserRolesLoading, (userRoleLoading) => userRoleLoading);
export const getUserRolesFetchedSelector = createSelector(getUserRolesFetched, (userRoleFetched) => userRoleFetched);

const getIsCustomer = (state: AppState) => state.userReducer.isCustomer;
export const getIsCustomerSelector = createSelector(getIsCustomer, (isCustomer) => isCustomer);

const theme = (state: AppState) => state.userReducer.theme;
export const themeSelector = createSelector(theme, (theme) => theme);

const temperturePreference = (state: AppState) => state.userReducer.temperature;
export const temperturePreferenceSelector = createSelector(temperturePreference, (temperature) => temperature);

const temperturePreferenceLoading = (state: AppState) => state.userReducer.isTemperatureLoding;
export const temperturePreferenceLoadingSelector = createSelector(
    temperturePreferenceLoading,
    (isTemperatureLoding) => isTemperatureLoding,
);
