import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import {
    fetchFilterRequestFailure,
    fetchFilterRequestSuccess,
    fetchRangesRequestFailure,
    fetchRangesRequestSuccess,
} from 'redux/actions';
import { IFilter } from 'shared/model';
import { getRanges } from 'api';
import { FetchRangesRequestSuccessRespose } from 'redux/types';
import store from 'redux/store/configStore';
import { getFilterAPI } from 'api/SharedAPI';

/*
  Worker Saga: Fired on FETCH_FILTER_VALUE action
*/
function* fetchFilterSaga() {
    try {
        const response: IFilter = yield call(getFilterAPI);
        yield put(fetchFilterRequestSuccess(response));
    } catch (e: any) {
        yield put(
            fetchFilterRequestFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on FETCH_RANGES_VALUE action
*/
function* fetchRangesSaga() {
    try {
        const response: FetchRangesRequestSuccessRespose = yield call(getRanges);
        response.temperaturePreference = store.getState().userReducer.temperature;
        yield put(fetchRangesRequestSuccess(response));
    } catch (e: any) {
        yield put(
            fetchRangesRequestFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_FILTER_VALUE`   action.
*/
function* sharedSaga() {
    yield all([takeLatest(actionTypes.FETCH_FILTER_VALUE, fetchFilterSaga)]);
    yield all([takeLatest(actionTypes.FETCH_RANGES_VALUE, fetchRangesSaga)]);
}

export default sharedSaga;
