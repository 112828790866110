import { Effect, all, call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import {
    addFirmwareRequestFailure,
    addFirmwareRequestSuccess,
    deleteFirmwareRequestFailure,
    deleteFirmwareRequestSuccess,
    fetchFirmwareLatestVersionSuccess,
    fetchFirmwareList,
    fetchFirmwareListFailure,
    fetchFirmwareListSuccess,
    fetchModelNameFailure,
    fetchModelNameSuccess,
    fetchSpecificFirmwareDataFailure,
    fetchSpecificFirmwareDataSuccess,
    updateFirmwareRequestSuccess,
    updatedFirmwareRequestFailure,
} from 'redux/actions/firmwaresAction';
import {
    addFirmwareDataAPI,
    deleteFirmware,
    getFirmwareListAPI,
    getLatestFirmwareVersionAPI,
    getModelNameAPI,
    getSpecificFirmwareData,
    updateFirmwareDataAPI,
} from 'api/firmwaresAPI';
import {
    FirmwareLatestVersionResponse,
    FirmwareListInterface,
    ModelNameInterface,
    ParticularFirmwareData,
} from 'redux/types/firmwareActionModel';
import { setToastAction } from 'redux/actions';
import { ToastVariant } from 'shared/utils/Constants';
import store from 'redux/store/configStore';

function* fetchModelNameSaga() {
    try {
        const response: ModelNameInterface = yield call(getModelNameAPI);
        yield put(fetchModelNameSuccess(response));
    } catch (e: any) {
        yield put(
            fetchModelNameFailure({
                error: e?.response?.data,
            }),
        );
    }
}
function* addFirmwareSaga(action: Effect) {
    try {
        const response: string = yield call(addFirmwareDataAPI, action.payload);
        yield put(addFirmwareRequestSuccess(response));
        yield put(setToastAction(ToastVariant.SUCCESS, response));
    } catch (e: any) {
        yield put(
            addFirmwareRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}
function* updateFirmwareSaga(action: Effect) {
    try {
        const response: string = yield call(updateFirmwareDataAPI, action.payload);
        yield put(updateFirmwareRequestSuccess(response));
        yield put(setToastAction(ToastVariant.SUCCESS, response));
    } catch (e: any) {
        yield put(
            updatedFirmwareRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}
function* deleteFirmwareSaga(action: Effect) {
    try {
        const response: string = yield call(deleteFirmware, action.payload.modelName, action.payload.version);
        yield put(deleteFirmwareRequestSuccess(response));
        store.dispatch(fetchFirmwareList());
        yield put(setToastAction(ToastVariant.SUCCESS, response));
    } catch (e: any) {
        yield put(
            deleteFirmwareRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}
function* fetchFirmwareListSaga() {
    try {
        const response: FirmwareListInterface[] = yield call(getFirmwareListAPI);
        yield put(fetchFirmwareListSuccess(response));
    } catch (e: any) {
        yield put(
            fetchFirmwareListFailure({
                error: e?.response?.data,
            }),
        );
    }
}

function* fetchFirmwareSpecificData(action: Effect) {
    try {
        const response: ParticularFirmwareData = yield call(
            getSpecificFirmwareData,
            action.payload.modelName,
            action.payload.version,
        );
        yield put(fetchSpecificFirmwareDataSuccess(response));
    } catch (e: any) {
        yield put(
            fetchSpecificFirmwareDataFailure({
                error: e.message,
            }),
        );
    }
}
function* fetchFirmwareLatestVersion(action: Effect) {
    try {
        const response: FirmwareLatestVersionResponse[] = yield call(getLatestFirmwareVersionAPI, action.payload);
        yield put(fetchFirmwareLatestVersionSuccess(response));
    } catch (e: any) {
        yield put(
            fetchFirmwareListFailure({
                error: e?.response?.data,
            }),
        );
    }
}

function* firmwareSaga() {
    yield all([takeLatest(actionTypes.FETCH_MODEL_NAME, fetchModelNameSaga)]);
    yield all([takeLatest(actionTypes.ADD_FIRMWARE_REQUEST, addFirmwareSaga)]);
    yield all([takeLatest(actionTypes.UPDATE_FIRMWARE_REQUEST, updateFirmwareSaga)]);
    yield all([takeLatest(actionTypes.FETCH_FIRMWARE_LIST, fetchFirmwareListSaga)]);
    yield all([takeLatest(actionTypes.FETCH_SPECIFIC_FIRMWARE_DATA, fetchFirmwareSpecificData)]);
    yield all([takeLatest(actionTypes.DELETE_FIRMWARE_REQUEST, deleteFirmwareSaga)]);
    yield all([takeLatest(actionTypes.FETCH_FIRMWARE_LATEST_VERSION, fetchFirmwareLatestVersion)]);
}

export default firmwareSaga;
