import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { loginRequestSuccess } from 'redux/actions';
import store from 'redux/store/configStore';
import { logoutService } from 'shared/service/CommonService';
import BaseAPI, { http_post } from './BaseURLAxios';
import { ACTIVATE, LOGIN_URL, REFRESH_URL, VALIDATE } from './urlConstants';

let isAlreadyFetchingAccessToken = false;
let subscribers: Array<(access_token: string) => void> = [];
/**
 * Add exempted url in the below list where we dont need to call the refresh token
 * eg:- in case of login api fails it wil return 401 so we dont have to call refresh logic in this case
 */
const exemptedRefreshTokensUrls = [LOGIN_URL, REFRESH_URL, VALIDATE, ACTIVATE];

/**
 * Method to Handle error coming from backend, it will not return the error to the caller in case of 401
 * @param error
 * @returns
 */
export const handleResponseError = (error: any) => {
    const { config, response } = error;
    const originalRequest = config;
    if (response?.status === 401) {
        if (originalRequest.url?.includes('refresh')) {
            // Logout logic
            return Promise.reject(error);
        }
        // Check if refresh API is already called or not
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            subscribers = [];
            return handle401(error);
        } else {
            return new Promise((resolve) => {
                addSubscriber((access_token: string) => {
                    originalRequest.headers.Authorization = 'Bearer ' + access_token;
                    resolve(axios(originalRequest));
                });
            });
        }
    }
    return Promise.reject(error);
};

/**
 * This method will hold all the API in case of 401 error until refresh token API will get
 * new token and will call previous API again with new token
 * @param error
 * @returns axios config with updted header
 */
export const handle401 = (error: AxiosError) => {
    return getRefreshToken()
        .then((res: any) => {
            isAlreadyFetchingAccessToken = false;
            if (res?.access_token && res?.refresh_token && error.response) {
                isAlreadyFetchingAccessToken = false;
                // Update token in request and redux store
                store.dispatch(loginRequestSuccess(res));
                BaseAPI.defaults.headers.common['Authorization'] = 'Bearer ' + res.access_token;
                error.response.config.headers['Authorization'] = 'Bearer ' + res.access_token;
                onAccessTokenFetched(res.access_token);
                return axios(error.response.config);
            } else {
                // Logout user logic
                logoutService();
                return Promise.reject(error);
            }
        })
        .catch((error: AxiosError) => {
            isAlreadyFetchingAccessToken = false;
            // If refresh token api fails then logout the user
            logoutService();
            return Promise.reject(error);
        });
};

/**
 * Method to attach token to every outgoing request from UI
 * @param config
 * @returns
 */
export const addTokenToRequest = (config: InternalAxiosRequestConfig) => {
    // add token from the store or local storage
    const token = store.getState().authReducer.accessToken;
    token && Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    return config;
};

/**
 * This funtion will call all the failed API which are called before the refresh token
 * @param access_token
 */
export const onAccessTokenFetched = (access_token: string) => {
    subscribers = subscribers.filter((callback: any) => callback(access_token));
};

/**
 * This funtion will save and hold the failed api when refresh token is fetching the new token and
 * the failed api will get called with new token
 * @param callback
 */
export const addSubscriber = (callback: (access_token: string) => void) => {
    subscribers.push(callback);
};

/**
 * return which all urls are not in the exempted list for showing an error or calling refresh token
 * @param url
 * @returns boolean
 */
export const exemptedUrls = (url?: string) => {
    return (
        url &&
        exemptedRefreshTokensUrls.some((exenptedUrl: string) => {
            return url.includes(exenptedUrl);
        })
    );
};

const getRefreshToken = async () => {
    const body = { refresh_token: store.getState().authReducer.refreshToken };
    return http_post(REFRESH_URL, body).then((res) => {
        return res;
    });
};
