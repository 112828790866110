import { http_delete, http_get, http_post, http_put } from './BaseURLAxios';
import { DEVICE, FIRMWARE, LATEST_VERSION, MODEL } from './urlConstants';
import { FirmwareLatestVersion } from 'redux/types/firmwareActionModel';

export const getModelNameAPI = async () => {
    return await http_get(DEVICE + '/' + MODEL).then((res: any) => {
        return res;
    });
};
export const addFirmwareDataAPI = async (body: {
    firmware_binary_file: FormData;
    other_files?: FormData;
    version_name: FormData;
    model_name: FormData;
    description: FormData;
}) => {
    return await http_post(FIRMWARE, body).then((res: any) => {
        return res;
    });
};
export const updateFirmwareDataAPI = async (body: {
    firmware_binary_file?: FormData;
    other_files?: FormData;
    version_name: FormData;
    model_name: FormData;
    description?: FormData;
    delete_other_file?: FormData;
}) => {
    return await http_put(FIRMWARE, body).then((res: any) => {
        return res;
    });
};
export const deleteFirmware = async (modelName: string, version: string) => {
    return http_delete('/' + FIRMWARE + '/' + modelName + '/' + version).then((res: any) => {
        return res;
    });
};
export const getFirmwareListAPI = async () => {
    return await http_get('/' + FIRMWARE).then((res: any) => {
        return res;
    });
};
export const getSpecificFirmwareData = async (modelName: string, version: string) => {
    return await http_get('/' + FIRMWARE + '/' + modelName + '/' + version).then((res: any) => {
        return res;
    });
};
export const getLatestFirmwareVersionAPI = async (body: FirmwareLatestVersion) => {
    return await http_post('/' + FIRMWARE + '/' + LATEST_VERSION, body).then((res: any) => {
        return res;
    });
};
