import {
    AddFirmwareFailureInterface,
    AddFirmwareRequest,
    AddFirmwareRequestFailure,
    AddFirmwareRequestSuccess,
    ChangePage,
    ChangeRowsPerPage,
    ClearFirmwareData,
    DeleteFirmwareRequest,
    DeleteFirmwareRequestFailure,
    DeleteFirmwareRequestSuccess,
    FetchFirmwareLatestVersion,
    FetchFirmwareLatestVersionFailure,
    FetchFirmwareLatestVersionFailureInterface,
    FetchFirmwareLatestVersionSuccess,
    FetchFirmwareList,
    FetchFirmwareListFailure,
    FetchFirmwareListSuccess,
    FetchModelName,
    FetchModelNameFailure,
    FetchModelNameSuccess,
    FetchSpecificFirmwareData,
    FetchSpecificFirmwareDataFailure,
    FetchSpecificFirmwareDataSuccess,
    FirmwareLatestVersion,
    FirmwareLatestVersionResponse,
    FirmwareListInterface,
    ModelNameFailureInterface,
    ModelNameInterface,
    ParticularFirmwareData,
    SetAddFirmwareOpened,
    SetEditFirmwareOpened,
    SetFirmwareList,
    SetViewFirmwareOpened,
    UpdateFirmwareRequest,
    UpdateFirmwareRequestFailure,
    UpdateFirmwareRequestSuccess,
} from 'redux/types/firmwareActionModel';
import * as actionTypes from '../actionTypes';

export const fetchModelName = (): FetchModelName => ({
    type: actionTypes.FETCH_MODEL_NAME,
});
export const fetchModelNameSuccess = (payload: ModelNameInterface): FetchModelNameSuccess => ({
    type: actionTypes.FETCH_MODEL_NAME_SUCCESS,
    payload,
});
export const fetchModelNameFailure = (payload: ModelNameFailureInterface): FetchModelNameFailure => ({
    type: actionTypes.FETCH_MODEL_NAME_FAILURE,
    payload,
});

export const addFirmwareRequest = (firmware: FormData): AddFirmwareRequest => ({
    type: actionTypes.ADD_FIRMWARE_REQUEST,
    payload: firmware,
});
export const addFirmwareRequestSuccess = (payload: string): AddFirmwareRequestSuccess => ({
    type: actionTypes.ADD_FIRMWARE_SUCCESS,
    payload,
});

export const addFirmwareRequestFailure = (payload: AddFirmwareFailureInterface): AddFirmwareRequestFailure => ({
    type: actionTypes.ADD_FIRMWARE_FAILURE,
    payload,
});
export const updateFirmwareRequest = (firmware: FormData): UpdateFirmwareRequest => ({
    type: actionTypes.UPDATE_FIRMWARE_REQUEST,
    payload: firmware,
});
export const updateFirmwareRequestSuccess = (payload: string): UpdateFirmwareRequestSuccess => ({
    type: actionTypes.UPDATE_FIRMWARE_SUCCESS,
    payload,
});

export const updatedFirmwareRequestFailure = (payload: AddFirmwareFailureInterface): UpdateFirmwareRequestFailure => ({
    type: actionTypes.UPDATE_FIRMWARE_FAILURE,
    payload,
});

export const deleteFirmwareRequest = (payload: { modelName: string; version: string }): DeleteFirmwareRequest => ({
    type: actionTypes.DELETE_FIRMWARE_REQUEST,
    payload,
});
export const deleteFirmwareRequestSuccess = (payload: string): DeleteFirmwareRequestSuccess => ({
    type: actionTypes.DELETE_FIRMWARE_SUCCESS,
    payload,
});

export const deleteFirmwareRequestFailure = (payload: AddFirmwareFailureInterface): DeleteFirmwareRequestFailure => ({
    type: actionTypes.DELETE_FIRMWARE_FAILURE,
    payload,
});

export const setAddFirmwareSuccess = (payload: boolean): any => ({
    type: actionTypes.SET_ADD_FIRMWARE_SUCCESS,
    payload,
});
export const fetchFirmwareList = (): FetchFirmwareList => ({
    type: actionTypes.FETCH_FIRMWARE_LIST,
});
export const fetchFirmwareListSuccess = (payload: FirmwareListInterface[]): FetchFirmwareListSuccess => ({
    type: actionTypes.FETCH_FIRMWARE_LIST_SUCCESS,
    payload,
});
export const fetchFirmwareListFailure = (payload: ModelNameFailureInterface): FetchFirmwareListFailure => ({
    type: actionTypes.FETCH_FIRMWARE_LIST_FAILURE,
    payload,
});

export const changePage = (payload: number): ChangePage => ({
    type: actionTypes.CHANGE_PAGE,
    payload,
});

export const changeRowsPerPage = (payload: number): ChangeRowsPerPage => ({
    type: actionTypes.CHANGE_ROWS_PER_PAGE,
    payload,
});

export const setFirmwareList = (payload: FirmwareListInterface[]): SetFirmwareList => ({
    type: actionTypes.UPDATE_FIRMWARE_LIST,
    payload,
});
export const setViewFirmwareOpened = (payload: boolean): SetViewFirmwareOpened => ({
    type: actionTypes.SET_VIEW_FIRMWARE_OPEN,
    payload,
});
export const setAddFirmwareOpened = (payload: boolean): SetAddFirmwareOpened => ({
    type: actionTypes.SET_ADD_FIRMWARE_OPEN,
    payload,
});
export const setEditFirmwareOpened = (payload: boolean): SetEditFirmwareOpened => ({
    type: actionTypes.SET_EDIT_FIRMWARE_OPEN,
    payload,
});
export const clearFirmwareData = (): ClearFirmwareData => ({
    type: actionTypes.CLEAR_FIRMWARE_DATA,
});
export const fetchSpecificFirmwareData = (payload: {
    modelName: string;
    version: string;
}): FetchSpecificFirmwareData => ({
    type: actionTypes.FETCH_SPECIFIC_FIRMWARE_DATA,
    payload,
});
export const fetchSpecificFirmwareDataSuccess = (
    payload: ParticularFirmwareData,
): FetchSpecificFirmwareDataSuccess => ({
    type: actionTypes.FETCH_SPECIFIC_FIRMWARE_DATA_SUCCESS,
    payload,
});
export const fetchSpecificFirmwareDataFailure = (
    payload: ModelNameFailureInterface,
): FetchSpecificFirmwareDataFailure => ({
    type: actionTypes.FETCH_SPECIFIC_FIRMWARE_DATA_FAILURE,
    payload,
});
export const fetchFirmwareLatestVersion = (payload: FirmwareLatestVersion): FetchFirmwareLatestVersion => ({
    type: actionTypes.FETCH_FIRMWARE_LATEST_VERSION,
    payload,
});
export const fetchFirmwareLatestVersionSuccess = (payload: FirmwareLatestVersionResponse[]): FetchFirmwareLatestVersionSuccess => ({
    type: actionTypes.FETCH_FIRMWARE_LATEST_VERSION_SUCCESS,
    payload,
});
export const fetchFirmwareLatestVersionFailure = (
    payload: FetchFirmwareLatestVersionFailureInterface,
): FetchFirmwareLatestVersionFailure => ({
    type: actionTypes.FETCH_FIRMWARE_LATEST_VERSION_FAILURE,
    payload,
});
