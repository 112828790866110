import React from 'react';

const Waves = () => {
    return (
        <svg viewBox="0 0 1440 514" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1705 311.915C1480.16 55.9237 1338.37 -4.3683 1245.33 1.37206C1177.52 5.55017 1148.62 44.8063 1095.68 75.3246C1068.21 91.5344 1036.02 103.716 1001.16 111.093C888.267 134.854 819.344 94.0353 694.246 74.2529C662.569 69.1643 630.27 66.0208 597.748 64.8612C430.167 58.9392 217.267 98.5404 185.101 192.312"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1693.89 323.793C1471.88 71.635 1330.12 9.32663 1235.81 12.6328C1167.78 14.9943 1137.32 52.1432 1084.22 80.8995C1056.16 96.4791 1023.98 108.361 989.369 115.923C878.044 140.065 809.31 104.297 683.591 85.5318C651.948 80.7403 619.805 77.5944 587.444 76.1219C419.161 68.6558 208.745 99.2105 173.15 189.149"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1682.84 335.64C1463.59 87.3515 1321.75 21.2647 1226.25 23.8806C1158.08 25.6971 1125.97 59.3582 1072.77 86.4977C1044.13 101.419 1011.96 113.001 977.621 120.758C867.863 145.282 799.372 114.564 673.22 96.834C641.588 92.2916 609.554 89.1242 577.317 87.3515C408.331 78.2687 200.4 99.8314 161.402 185.955"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1671.57 347.481C1455.3 103.079 1313.56 36.8473 1216.74 35.0307C1148.36 33.9771 1114.6 66.6753 1061.31 92.1073C1032.13 106.366 999.974 117.653 965.894 125.605C857.703 150.51 789.374 124.824 662.763 108.129C631.139 103.858 599.21 100.681 567.102 98.6106C397.414 87.9837 191.969 100.427 149.567 182.772"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1660.45 359.336C1447.09 118.804 1305.33 50.5193 1207.21 46.3957C1138.67 43.4528 1103.18 73.8441 1049.86 97.6956C1020.16 111.277 988.004 122.265 954.177 130.394C847.579 155.699 779.412 135.081 652.315 119.367C622.742 115.734 591.17 112.246 556.898 109.794C386.481 97.5685 183.546 101.093 137.742 179.568"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1649.32 371.176C1438.74 134.532 1297.09 64.3758 1197.7 57.6C1128.97 52.9314 1091.72 81.0155 1038.36 103.25C1008.12 116.148 975.951 126.858 942.371 135.204C837.339 160.89 769.388 145.286 641.778 130.644C612.07 127.229 580.579 123.778 546.577 121.016C375.674 107.21 175.116 101.724 125.909 176.349"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1638.18 383.004C1430.46 150.247 1288.86 78.2015 1188.18 68.9006C1119.28 62.5245 1080.15 88.229 1027 108.865C996.287 121.071 964.097 131.511 930.772 140.074C827.28 166.142 759.518 155.587 631.422 141.89C601.713 138.693 570.196 135.242 536.463 132.19C364.669 116.713 166.704 102.253 114.094 173.063"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1627.05 394.844C1422.2 165.956 1280.62 92.0581 1178.72 80.1414C1109.67 72.0759 1068.75 95.3643 1015.6 114.475C984.408 125.962 952.179 136.124 919.101 144.902C817.202 171.351 749.629 165.829 621.019 153.258C591.176 150.316 559.739 146.846 526.304 143.522C353.808 126.373 158.354 102.976 102.287 169.953"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1615.91 406.683C1413.89 181.683 1272.32 105.913 1169.12 91.3809C1099.88 81.6441 1057.15 102.48 1004.11 120.065C973.616 130.165 942.396 140.356 907.313 149.729C806.98 176.542 739.597 176.088 610.501 164.534C580.577 161.827 549.194 158.34 515.975 154.743C342.776 135.923 149.808 103.588 90.3644 166.75"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1604.78 418.525C1405.6 197.467 1264.22 119.808 1159.62 102.642C1090.18 91.2155 1045.57 109.563 992.661 125.658C961.44 135.158 930.138 145.059 895.596 154.559C796.83 181.808 729.662 186.331 600.053 175.813C570.048 173.361 538.692 169.855 505.824 165.967C331.95 145.44 141.44 104.204 78.5932 163.551"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1593.74 430.373C1397.37 213.129 1255.85 133.727 1150.14 113.908C1080.52 100.847 1034.01 116.633 981.237 131.274C949.26 140.139 917.904 149.767 884.01 159.413C786.783 186.988 719.858 196.616 589.628 187.134C559.515 184.899 528.24 181.375 495.642 177.233C321.066 154.962 133.04 104.825 66.7909 160.358"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1582.5 442.198C1389.04 228.842 1247.6 147.678 1140.57 125.152C1070.73 110.438 1022.36 123.645 969.779 136.851C937.046 145.062 905.582 154.454 872.147 164.227C776.54 192.184 709.751 206.843 579.143 198.396C547.653 196.138 516.355 192.798 485.373 188.387C310.148 164.463 124.499 105.479 54.9279 157.142"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1571.37 454.036C1380.76 244.567 1239.18 161.568 1131.15 136.39C1061.1 120.041 1010.83 130.632 958.305 142.44C924.816 149.978 893.298 159.134 860.43 169.052C766.363 197.391 699.789 217.101 568.749 209.671C537.287 207.688 506.046 204.345 475.222 199.662C299.269 173.848 116.158 106.09 43.076 153.866"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1560.24 465.888C1372.49 260.288 1230.75 175.6 1121.53 147.643C1051.31 129.695 999.136 137.597 946.876 148.024C912.631 154.873 881.059 163.847 848.731 173.892C756.258 202.612 689.846 227.354 558.239 220.96C526.805 219.228 495.618 215.863 464.955 210.896C288.408 183.375 107.728 106.734 31.2424 150.749"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1549.09 477.735C1364.2 276.096 1222.55 189.573 1112.03 158.909C1041.52 139.326 987.528 144.522 935.458 153.623C900.348 159.745 868.804 168.537 837.016 178.728C746.109 207.793 679.913 237.621 547.793 232.262C516.391 230.771 485.253 227.384 454.752 222.144C277.556 192.824 99.3071 107.355 19.419 147.556"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1537.97 489.581C1356.05 291.739 1214.18 203.581 1102.5 170.228C1031.74 149.047 975.947 151.5 924.012 159.329C888.227 164.779 856.493 173.353 825.299 183.671C735.959 213.154 669.952 247.996 537.346 243.618C505.978 242.366 474.883 238.958 444.548 233.445C266.704 202.309 90.7506 108.102 7.59466 144.433"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1526.85 501.421C1347.65 307.466 1205.78 217.582 1093 181.414C1021.94 158.616 964.365 158.289 912.565 164.811C876.024 169.425 844.263 177.944 813.582 188.426C725.835 218.29 660.018 258.164 526.898 254.858C495.564 253.856 464.507 250.424 434.344 244.631C255.878 211.624 82.4635 108.606 -4.23044 141.159"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path
                d="M1515.7 513.245C1339.37 323.177 1197.39 231.677 1083.58 192.657C1012.25 168.278 952.92 165.136 901.254 170.404C863.957 174.2 832.115 182.629 802.002 193.202C715.794 223.466 650.166 268.39 516.56 266.101C485.255 265.33 454.225 261.874 424.221 255.819C245.27 220.795 74.1507 109.24 -16.0002 137.942"
                stroke="white"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default Waves;
