import { REMOVE_TOKENS, REMOVE_USER } from 'redux/actionTypes';
import store from 'redux/store/configStore';
import {
    AlertType,
    AQI,
    DATETIME_FORMAT,
    dayMappingReverse,
    daysOfWeekString,
    DeviceAlertsEnum,
    DeviceType,
    LabelColorSet,
    LocalStorageKey,
    NotAllowedSpecialCharacters,
    occuranceAliasMap,
    OtherEnums,
    Performance_Benefits,
    PollutantEnum,
    PollutantSymbolByName,
    PollutantSymbolMapping,
    rangeAliasMap,
    rangeAliasMapsReverse,
    RangeValues,
    RoomComform,
    Schedulelevel,
    SiteLocationEnum,
    StatusEnumColors,
    Theme,
    ThemeVariable,
    ThemeVariableValues,
    ThingData,
    TimeEnums,
    AirCleaningLevelLabelColorSetDark,
    AirCleaningLevelLabelColorSetWhite,
    USER_ROLE_ID,
    PlannedAirCleaningValues,
} from 'shared/utils/Constants';
import * as crypto from 'crypto-js';
import { Thing } from 'shared/model/DeviceModel';
import moment from 'moment';
import { NodeElement, SiteAllocatedDevices } from 'shared/model/SiteModel';
import { ScaleType, ScaleTypeValue } from 'shared/Components/charts/LineChart';
import {
    AqiRange,
    BarData,
    IDASHBOARDAQIVALUE,
    IDASHBOARDGRAPH,
    IDASHBOARDVALUE,
    MappedGraphValues,
    MappedGraphValuesWithLabels,
    PollutantRange,
    PollutantValues,
    VirusAndProductivityRange,
} from 'redux/types';
import routes, { DashboardRoute, ManageSiteRoute } from 'routes';
import navigation, { servicer_nav, DashboardNav, SiteNav } from 'containers/_nav';
import { Scale } from 'chart.js';
import {
    ManualAirCleaning,
    PlannedAirCleaningInterface,
    PlannedAirCleaningSchedule,
    RemoteCommandsTableData,
    RemoteCommandsTableThingList,
    Schedule,
    ScheduledPlannedAirCleaingApiStructure,
} from 'redux/types/deviceCommandModel';
import { t } from 'i18next';
import { INITIAL_STATE_TYPE } from 'views/managesite/component/AddDeviceReducer';

export const getEncryptedString = async (value: string) => {
    return await crypto.SHA256(value).toString();
};

/**
 * Funtion to generate a unique Id
 * @returns uniqueId - string
 */
export const generateGuid = () => {
    let result, i, j;
    result = '';
    for (j = 0; j < 32; j++) {
        if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
        i = Math.floor(Math.random() * 16)
            .toString(16)
            .toUpperCase();
        result = result + i;
    }
    return result;
};

export const logoutService = () => {
    removeTokenFromLocalStorage();
    removeDeviceFromLocalStorage();
    store.dispatch({ type: REMOVE_TOKENS });
    store.dispatch({ type: REMOVE_USER });
};

export const getErrorMessageWithLength = (item: string, max: number, min: number, t: any, last_word = 'character') => {
    return (
        item +
        ' ' +
        t('should_be') +
        ' ' +
        t('greater_than') +
        ' ' +
        (min - 1) +
        ' ' +
        t('and') +
        ' ' +
        t('less_than') +
        ' ' +
        +max +
        ' ' +
        t(last_word)
    );
};

export const getKeyByValue = (object: any, value: any) => {
    for (const prop in object) {
        if (object.hasOwnProperty(prop) && object[prop] === value) {
            return prop;
        }
    }
};

export const getDeviceAlertIcon = (value: DeviceAlertsEnum, size = 'h3') => {
    switch (value) {
        case AlertType.FILTERHEALTH:
            return 'ri-filter-line ' + size;
        case AlertType.HIGHTEMP:
            return 'icon-temperature_high px-3 ' + size;
        case AlertType.HIGHVOC:
            return 'ri-blaze-line ' + size;
        case AlertType.RENEWALFAILURE:
            return 'icon-renewal_failure ' + size;
        case OtherEnums.OFFLINE:
            return 'ri-signal-wifi-off-line ' + size;
        default:
            return 'ri-subtract-line ' + size;
    }
};

export const getDeviceAlertOuterClass = (value: DeviceAlertsEnum) => {
    switch (value) {
        case AlertType.FILTERHEALTH:
            return 'text-hazardous border-hazardous bg-hazardous-light ';
        case AlertType.HIGHTEMP:
            return 'text-hazardous border-hazardous bg-hazardous-light ';
        case AlertType.HIGHVOC:
            return 'text-moderate border-moderate bg-tertiary-light ';
        case AlertType.RENEWALFAILURE:
            return 'text-hazardous border-hazardous bg-hazardous-light p-2 ';
        case OtherEnums.OFFLINE:
            return 'text-gray border-gray bg-gray-20-opacity';
        default:
            return 'text-gray border-gray bg-gray-20-opacity';
    }
};

export interface IAvaibaleInstalled {
    available: Thing[];
    installed: Thing[];
}
export const getAvailableAndInstalledDevices = (arr: Thing[]): IAvaibaleInstalled => {
    return arr.reduce(
        (previousValue: IAvaibaleInstalled, currentValue: Thing) => {
            if (currentValue?.attributes?.location === SiteLocationEnum.AtSiteInstalled) {
                previousValue.installed.push(currentValue);
            } else if (currentValue?.attributes?.location === SiteLocationEnum.AtSite) {
                previousValue.available.push(currentValue);
            }
            return previousValue;
        },
        {
            available: [],
            installed: [],
        },
    );
};

export const getAvailableAndInstalledDevicesWithSorting = (thingsData: Thing[]) => {
    const { available, installed } = getAvailableAndInstalledDevices(thingsData);

    const sortedAvailableDevices = sortDevices(available);
    const sortedInstalledDevices = sortDevices(installed);
    return { available: sortedAvailableDevices, installed: sortedInstalledDevices };
};

const sortDevices = (devices: Thing[]) => {
    return devices.sort(sortFunction);
};

const sortFunction = (
    a: { thingTypeName: string; thingName: string },
    b: { thingTypeName: string; thingName: string },
) => {
    if (a.thingTypeName === b.thingTypeName) {
        return a.thingName.localeCompare(b.thingName, undefined, { numeric: true });
    }
    return a.thingTypeName.localeCompare(b.thingTypeName);
};

export const getTimeBeforeTodayDate = (time: TimeEnums) => {
    switch (time) {
        case TimeEnums.LAST_ONE_HOUR:
            return moment().subtract(1, 'hour').valueOf().toString();
        case TimeEnums.LAST_SIX_HOUR:
            return moment().subtract(6, 'hour').valueOf().toString();
        case TimeEnums.LAST_TWELVE_HOUR:
            return moment().subtract(12, 'hour').valueOf().toString();
        case TimeEnums.LAST_ONE_DAY:
            return moment().subtract(1, 'day').valueOf().toString();
        case TimeEnums.LAST_TWO_DAY:
            return moment().subtract(2, 'day').valueOf().toString();
        case TimeEnums.LAST_THIRTY_DAY:
            return moment().subtract(1, 'month').valueOf().toString();
        case TimeEnums.LAST_SIX_MONTH:
            return moment().subtract(6, 'months').valueOf().toString();
        case TimeEnums.LAST_ONE_YEAR:
            return moment().subtract(1, 'year').valueOf().toString();
        default:
            return moment().subtract(1, 'hour').valueOf().toString();
    }
};

export const getTimeIntervalValue = (time: TimeEnums) => {
    const currentTime = moment();
    const dateArr = [];
    let endTime = moment();
    const subtractValue: { value: number; type: any } = { value: 10, type: 'minute' };
    switch (time) {
        case TimeEnums.LAST_ONE_HOUR:
            endTime = moment().subtract(60, 'minutes');
            subtractValue.type = 'minute';
            subtractValue.value = 10;
            break;
        case TimeEnums.LAST_SIX_HOUR:
            endTime = moment().subtract(6, 'hour');
            subtractValue.type = 'hour';
            subtractValue.value = 1;
            break;
        case TimeEnums.LAST_TWELVE_HOUR:
            endTime = moment().subtract(12, 'hour');
            subtractValue.type = 'hour';
            subtractValue.value = 2;
            break;
        case TimeEnums.LAST_ONE_DAY:
            endTime = moment().subtract(24, 'hour');
            subtractValue.type = 'hour';
            subtractValue.value = 4;
            break;
        case TimeEnums.LAST_TWO_DAY:
            endTime = moment().subtract(48, 'hour');
            subtractValue.type = 'hour';
            subtractValue.value = 8;
            break;
        case TimeEnums.LAST_SEVEN_DAY:
            endTime = moment().subtract(7, 'day');
            subtractValue.type = 'day';
            subtractValue.value = 1;
            break;
        case TimeEnums.LAST_THIRTY_DAY:
            endTime = moment().subtract(30, 'day');
            subtractValue.type = 'day';
            subtractValue.value = 5;
            break;
        case TimeEnums.LAST_SIX_MONTH:
            endTime = moment().subtract(6, 'month');
            subtractValue.type = 'month';
            subtractValue.value = 1;
            break;
        case TimeEnums.LAST_ONE_YEAR:
            endTime = moment().subtract(12, 'month');
            subtractValue.type = 'month';
            subtractValue.value = 2;
            break;
        default:
            endTime = moment().subtract(1, 'hour');
            subtractValue.type = 'minutes';
            subtractValue.value = 10;
            break;
    }
    while (currentTime >= endTime) {
        dateArr.push(moment(currentTime));
        currentTime.subtract(subtractValue.value, subtractValue.type);
    }
    return dateArr.reverse();
};

export const getStepSize = (time: TimeEnums) => {
    switch (time) {
        case TimeEnums.LAST_ONE_HOUR:
            return 10;
        case TimeEnums.LAST_SIX_HOUR:
            return 1;
        case TimeEnums.LAST_TWELVE_HOUR:
            return 2;
        case TimeEnums.LAST_ONE_DAY:
            return 4;
        case TimeEnums.LAST_TWO_DAY:
            return 8;
        case TimeEnums.LAST_SEVEN_DAY:
            return 1;
        case TimeEnums.LAST_THIRTY_DAY:
            return 5;
        case TimeEnums.LAST_SIX_MONTH:
            return 1;
        case TimeEnums.LAST_ONE_YEAR:
            return 2;
        default:
            return 10;
    }
};

export const getTimeType = (time: TimeEnums) => {
    switch (time) {
        case TimeEnums.LAST_ONE_HOUR:
            return 'minute';
        case TimeEnums.LAST_SIX_HOUR:
            return 'hour';
        case TimeEnums.LAST_TWELVE_HOUR:
            return 'hour';
        case TimeEnums.LAST_ONE_DAY:
            return 'hour';
        case TimeEnums.LAST_TWO_DAY:
            return 'hour';
        case TimeEnums.LAST_SEVEN_DAY:
            return 'day';
        case TimeEnums.LAST_THIRTY_DAY:
            return 'day';
        case TimeEnums.LAST_SIX_MONTH:
            return 'month';
        case TimeEnums.LAST_ONE_YEAR:
            return 'month';
        default:
            return 'minute';
    }
};

export const getTimeForRoundingType = (time: TimeEnums) => {
    switch (time) {
        case TimeEnums.LAST_ONE_HOUR:
            return 'minute';
        case TimeEnums.LAST_SIX_HOUR:
            return 'minute';
        case TimeEnums.LAST_TWELVE_HOUR:
            return 'minute';
        case TimeEnums.LAST_TWO_DAY:
            return 'minute';
        case TimeEnums.LAST_ONE_DAY:
            return 'minute';
        case TimeEnums.LAST_SEVEN_DAY:
            return 'minute';
        case TimeEnums.LAST_THIRTY_DAY:
            return 'hour';
        case TimeEnums.LAST_SIX_MONTH:
            return 'day';
        case TimeEnums.LAST_ONE_YEAR:
            return 'day';
        default:
            return 'minute';
    }
};

export const getSqftArea = (nodes: NodeElement[]): number => {
    return nodes.reduce((previousValue: number, currentValue: NodeElement) => {
        const availableMap = currentValue?.data?.thing_list?.filter(
            (thing: Thing) =>
                thing?.attributes?.location === SiteLocationEnum.AtSiteInstalled &&
                thing?.thingTypeName === DeviceType.PURIFIER,
        );
        const val = getSqftValueFromDevice(availableMap);
        previousValue += val ?? 0;
        return previousValue;
    }, 0);
};

export const getChildNodes = (localNodes: NodeElement[], childIds: Array<number>) => {
    return localNodes?.filter((node: NodeElement) => childIds.some((childid: number) => node.id === childid));
};

export const getChildDevices = (childNodes: NodeElement[]) => {
    return childNodes.reduce((previousValue: Thing[], currentValue: NodeElement) => {
        if (currentValue?.data?.thing_list) {
            previousValue.push(...currentValue.data.thing_list);
        }
        return previousValue;
    }, []);
};

export const getParentNodes = (nodes: NodeElement[], parent_id: any, arrayOfParentNodes: Array<any>) => {
    const parentNode = nodes.find((node: NodeElement) => node.id === parent_id);
    if (parentNode) {
        arrayOfParentNodes.unshift(parentNode);
        if (parentNode.parent_id !== parentNode.id) {
            getParentNodes(nodes, parentNode.parent_id, arrayOfParentNodes);
        }
    }
    return arrayOfParentNodes;
};

export const isParent = (node: NodeElement | undefined) => node?.id === node?.parent_id;
export const isOU = (node: NodeElement) => node?.site_details?.is_organizational_unit;

export const getSqftValueFromDevice = (things?: Thing[]) => {
    if (things && things?.length > 0) {
        return things?.reduce((prevVal: number, currentValue: Thing) => {
            if (
                currentValue?.attributes?.area_serviced_sq_feet &&
                currentValue?.thingTypeName === DeviceType.PURIFIER
            ) {
                const deviceSqftArea = currentValue?.attributes?.area_serviced_sq_feet
                    ? Number(currentValue?.attributes?.area_serviced_sq_feet)
                    : 0.0;
                return prevVal + deviceSqftArea;
            }
            return prevVal;
        }, 0);
    } else {
        return 0;
    }
};

export const convertToCurrency = (value?: any) => {
    return value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,') : '';
};

export const getLocalStorageValue = (key: LocalStorageKey) => {
    return localStorage.getItem(key);
};

export const setLocalStorageValue = (key: LocalStorageKey, value: string) => {
    return localStorage.setItem(key, value);
};

export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageKey.THEME);
    localStorage.removeItem(LocalStorageKey.ORG_ID);
};
export const removeDeviceFromLocalStorage = () => {
    localStorage.removeItem(LocalStorageKey.DEVICE_COUNT);
    localStorage.removeItem(LocalStorageKey.DEVICE_ID);
};

export const getThemeValue = (theme: Theme, variableName: string) => {
    return theme === Theme.DARK ? variableName : variableName + '-' + Theme.LIGHT;
};

export const getThemeLightDarkValue = (theme: Theme, darkClass: string, lightClass: string) => {
    return theme === Theme.DARK ? darkClass : lightClass;
};

export const getBackgound1 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BACKGROUND_1);
};

export const getBackgound2 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BACKGROUND_2);
};

export const getBackgound3 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BACKGROUND_3);
};

export const getText1 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.TEXT_1);
};

export const getText2 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.TEXT_2);
};

export const getText3 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.TEXT_3);
};

export const getBorder1 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BORDER_1);
};

export const getBorder1Value = (theme: Theme) => {
    return theme === Theme.DARK ? ThemeVariableValues.BORDER_1 : ThemeVariableValues.BORDER_1_LIGHT;
};

export const getBorder2 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BORDER_2);
};

export const getBorder3 = (theme: Theme) => {
    return getThemeValue(theme, ThemeVariable.BORDER_3);
};

function calculatePercentageValues(
    val: MappedGraphValuesWithLabels,
    pollutantRange: PollutantRange[],
    aqiRANGE: AqiRange[],
) {
    let high_value: number;
    let low_value: number;
    let tenPercentPollutant: number | string = 0;

    switch (true) {
        case val.title !== AQI.title &&
            val?.symbol !== PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3] &&
            val?.symbol !== PollutantSymbolMapping[PollutantSymbolByName.PRESSURE] &&
            val?.symbol !== PollutantSymbolMapping[PollutantSymbolByName.FANSPEED] &&
            val?.symbol !== PollutantSymbolMapping[PollutantSymbolByName.PPB]:
            {
                const range = getpollutantRange(pollutantRange, val?.title);
                high_value = range[range.length - 1]?.bp_high;
                low_value = range[0]?.bp_low;

                if (val.title === RoomComform.TEMPERATURE.title || val.title === RoomComform.HUMIDITY.title) {
                    tenPercentPollutant = 15;
                } else {
                    tenPercentPollutant = Math.round(range[range.length - 1]?.bp_high / 10);
                }
            }
            break;
        case val?.symbol === PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3]:
            high_value = 1000;
            low_value = 0;
            tenPercentPollutant = Math.round(high_value / 10);
            break;
        case val?.symbol === PollutantSymbolMapping[PollutantSymbolByName.PRESSURE]:
            high_value = 500;
            low_value = 0;
            tenPercentPollutant = Math.round(high_value / 10);
            break;
        case val?.symbol === PollutantSymbolMapping[PollutantSymbolByName.PPB]:
            high_value = 1382;
            low_value = 5;
            tenPercentPollutant = Math.round(high_value / 10);
            break;
        case val?.symbol === PollutantSymbolMapping[PollutantSymbolByName.FANSPEED]:
            high_value = 255;
            low_value = 0;
            break;
        default:
            high_value = aqiRANGE[aqiRANGE.length - 1]?.aqi_high;
            low_value = aqiRANGE[0]?.aqi_low;
            tenPercentPollutant = Math.round(aqiRANGE[aqiRANGE.length - 1]?.aqi_high / 10);
            break;
    }

    return { high_value, low_value, tenPercentPollutant };
}

/**checking if symbol is not of fan speed if yes then showinghigh and low values as it is but if not then Increasing the max value by 10% of there max value of that pollutant
 * Decreasing the min value by 10% of max value of that pollutant
 * In case of temperature and humidity increasing and decresing by 15
 * If after increasing the vlaues goes out of max value than capping to the max value same for min value
 * if the value is out of range than not adding any value to that point
 * now if range is empty
 */
const calculateGraphMaxMinValues = (
    valSymbol: string | undefined,
    maxedValue: number,
    minnedValue: number,
    high_value: number,
    low_value: number,
    tenPercentPollutant: number,
): { maxedValue: number; minnedValue: number } => {
    if (valSymbol !== PollutantSymbolMapping[PollutantSymbolByName.FANSPEED]) {
        maxedValue =
            maxedValue < high_value
                ? maxedValue + tenPercentPollutant > high_value
                    ? high_value
                    : maxedValue + tenPercentPollutant
                : maxedValue;

        minnedValue =
            minnedValue > low_value
                ? minnedValue - tenPercentPollutant < low_value
                    ? low_value
                    : minnedValue - tenPercentPollutant
                : minnedValue;
    } else {
        maxedValue = high_value;
        minnedValue = low_value;
    }

    return { maxedValue, minnedValue };
};

export const microGramPerUnit = PollutantSymbolMapping[PollutantSymbolByName.MICRO_PER_METER];
export const millionPerUnit = PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3];
export const axisarray = ['y', 'y1'];

export const createGraphDataMultiAxis = (
    values: any,
    orignalVaue: any,
    selectedTime: TimeEnums,
    theme: Theme,
    isFehreneit = false,
    pollutantRange: PollutantRange[] = [],
    aqiRANGE: AqiRange[] = [],
) => {
    const dataSets: any = [];
    const scales: ScaleType = {};
    const labels: any = getTimeIntervalValue(selectedTime);
    values.map((val: any, index: number) => {
        const mappedValueGraphData: any = [];
        let maxedGraphValue = 0;
        let minnedGraphValue = 0;
        orignalVaue.map((graphValue: any) => {
            if (graphValue?.[val.mappedValue]) {
                // Addding the dates to the labels as they are not coming in between the the date labels
                const xLabel = moment.utc(graphValue.binned_time).local().valueOf();
                const ylabel =
                    isFehreneit && val?.title === RoomComform.TEMPERATURE.title
                        ? Math.round(convertCToF(graphValue[val.mappedValue]))
                        : Math.round(graphValue[val.mappedValue]);

                if (mappedValueGraphData.length === 0) {
                    maxedGraphValue = ylabel;
                    minnedGraphValue = ylabel;
                } else if (ylabel > maxedGraphValue) {
                    maxedGraphValue = ylabel;
                } else if (ylabel < minnedGraphValue) {
                    minnedGraphValue = ylabel;
                }
                mappedValueGraphData.push({
                    x: xLabel,
                    y: ylabel,
                });
            }
        });
        if (mappedValueGraphData.length > 0) {
            /*Here if symbol is 'µg/m3' then we are setting its position to left 
              if there is 'µg/m3' symbol present in total values array then position will right
              if there is more than 1 value in total values or array 
              and check in total values if first value symbol is not equal to all other values symbols.i.e if array contains symbols are different from each other  then position set to right
              if no matching condition then position is set to left
            */
            const position =
                val.symbol === microGramPerUnit
                    ? 'left'
                    : values.some((val: any) => val.symbol === microGramPerUnit)
                    ? 'right'
                    : index > 0 && values.some((item: any) => item.symbol != values[0]?.symbol)
                    ? 'right'
                    : 'left';

            dataSets.push({
                data: mappedValueGraphData,
                label: val,
                fill: false,
                borderColor: val.color,
                pointRadius: 1,
                borderWidth: 1,
                yAxisID:
                    /*Here if symbol is 'µg/m3' then we are setting its axis to 0 
                    if there is 'µg/m3' symbol present in total values array then axis will 1
                    if there is more than 1 value in total values or array 
                    and check in total values if first value symbol is not equal to all other values symbol.i.e if array contains symbols are different from each other  then axis set to 1
                    if no matching condition then axis is set to 0
                    */
                    val.symbol === microGramPerUnit
                        ? axisarray[0]
                        : values.some((val: any) => val.symbol === microGramPerUnit)
                        ? axisarray[1]
                        : index > 0 && values.some((item: any) => item.symbol != values[0]?.symbol)
                        ? axisarray[1]
                        : axisarray[0],
                pointHoverBackgroundColor: val.color,
            });

            const result = calculatePercentageValues(val, pollutantRange, aqiRANGE);
            const high_value = result?.high_value;
            const low_value = result?.low_value;
            const tenPercentPollutant = result?.tenPercentPollutant;

            const { maxedValue, minnedValue } = calculateGraphMaxMinValues(
                val?.symbol,
                maxedGraphValue,
                minnedGraphValue,
                high_value,
                low_value,
                tenPercentPollutant,
            );

            /*Here if symbol is 'µg/m3' then we are setting its scale to 0 
              if there is 'µg/m3' symbol present in total values array then scale will 1
              if there is more than 1 value in total values or array 
              and check in total values if first value symbol is not equal to all other values symbol.i.e if array contains symbols are different from each other then scale set to 1
              if no matching condition then scale is set to 0
            */
            scales[
                val.symbol === microGramPerUnit
                    ? axisarray[0]
                    : values.some((val: any) => val.symbol === microGramPerUnit)
                    ? axisarray[1]
                    : index > 0 && values.some((item: any) => item.symbol != values[0]?.symbol)
                    ? axisarray[1]
                    : axisarray[0]
            ] = {
                type: 'linear',
                display: true,
                position: position,
                title: {
                    display: true,
                    text:
                        val?.symbol === PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3]
                            ? val.symbol
                            : `( ${
                                  isFehreneit && val?.title === RoomComform.TEMPERATURE.title
                                      ? PollutantSymbolByName.DEGREE_FAHRENHEIT
                                      : val.symbol
                              } )`,
                    color: val.color,
                },

                grid: {
                    display: true,
                    borderColor: getBorder1Value(theme),
                    color: getBorder1Value(theme),
                    borderDash: [2],
                    drawOnChartArea: true,
                },
                ticks: {
                    color: theme === Theme.DARK ? '#FFFFFF' : '#000',
                    callback: function (this: Scale, value: any) {
                        return value;
                    },
                    autoSkip: false,
                },
                max: checkForMinMaxVal(val, scales, Math.round(maxedValue)),
                min: checkForMinMaxVal(val, scales, Math.round(minnedValue), false),
                symbol: val?.symbol,
            };
        }
    });
    if (dataSets.length > 0) {
        return { labels, datasets: dataSets, scales: scales };
    }
};

const checkForMinMaxVal = (val: any, scales: any, minnedMaxedValue: number, isMax = true) => {
    return val.symbol === microGramPerUnit || millionPerUnit
        ? getMaxMinValue(
              minnedMaxedValue,
              val.symbol === microGramPerUnit ? microGramPerUnit : millionPerUnit,
              scales,
              isMax,
          )
        : minnedMaxedValue;
};

export const getMaxMinValue = (minMax: number, givenSymbol: string, scales: ScaleType, isMax = true) => {
    if (scales) {
        let minMaxValue = minMax;
        Object.values(scales).forEach((val: ScaleTypeValue) => {
            if (val.symbol === givenSymbol) {
                if (isMax && val.max !== undefined && minMaxValue < val.max) {
                    minMaxValue = val.max;
                } else if (!isMax && val.min !== undefined && val.min < minMaxValue) {
                    minMaxValue = val.min;
                }
            }
        });
        return minMaxValue;
    } else {
        return minMax;
    }
};

const getpollutantRange = (range: PollutantRange[], key: string) => {
    return range
        .filter((range: PollutantRange) => range.pollutant_name === key)
        .sort((a: PollutantRange, b: PollutantRange) => a.bp_high - b.bp_low);
};

export const createGraphDataSingleAxis = (
    values: any,
    selectedTime: TimeEnums,
    theme: Theme,
    max = 0,
    multiple = 10,
    ytitle = '( Risk Level in % )',
    showMaxMin = true,
) => {
    const dataSets: any = [];
    const scales: ScaleType = {};
    const dataValues: any = [];
    values?.map((data: any) => {
        if (data?.value) {
            dataValues.push({
                x: moment.utc(data.binned_time).local().valueOf(),
                y: Math.round(parseFloat(data?.value) * multiple),
            });
        }
    });
    if (dataValues.length > 0) {
        const labels: any = getTimeIntervalValue(selectedTime);
        dataSets.push({
            data: dataValues,
            fill: false,
            borderColor: '#36cb79',
            pointHoverBackgroundColor: '#36cb79',
            backgroundColor: '#36cb79',
            pointRadius: 1,
            borderWidth: 1,
        });
        scales['y'] = {
            type: 'linear',
            beginAtZero: true,
            display: true,
            grid: {
                display: true,
                borderColor: getBorder1Value(theme),
                color: getBorder1Value(theme),
                borderDash: [2],
                drawOnChartArea: true,
            },
            ticks: {
                color: theme === Theme.DARK ? '#FFFFFF' : '#000',
                callback: function (this: Scale, value: any) {
                    return value;
                },
                autoSkip: false,
            },
            title: {
                display: true,
                text: ytitle,
                color: theme === Theme.DARK ? '#FFFFFF' : '#000',
            },
            symbol: '',
            position: 'left',
        };
        if (showMaxMin) {
            scales['y'].max = max;
            scales['y'].min = 0;
        }
        return { labels, datasets: dataSets, scales: scales };
    }
};

export const createBarGraphData = (
    values: any,
    selectedTime: TimeEnums,
    theme: Theme,
    range: any,
    colors: string[],
    max = 0,
    ytitle = '( IAQ )',
) => {
    const arr: any = [];
    values?.map((data: any) => {
        const x = moment.utc(data.binned_time).local().valueOf();
        const barHeight = parseFloat(data?.max_input_aqi) - parseFloat(data?.min_input_aqi);
        const minValueRange = range.findIndex(
            (r: any) => r.aqi_low <= data?.min_input_aqi && r.aqi_high >= data?.min_input_aqi,
        );
        const maxValueRange = range.findIndex(
            (r: any) => r.aqi_low <= data?.max_input_aqi && r.aqi_high >= data?.max_input_aqi,
        );
        if (data?.min_input_aqi === data?.max_input_aqi) {
            // It means we have to add some value to show it in bar graph otherwise it will not be visible
            if (!arr?.[minValueRange]) {
                arr[minValueRange] = [];
            }
            arr[minValueRange].push({ x, y: [parseFloat(data?.min_input_aqi), parseFloat(data?.max_input_aqi) + 3] });
        } else if (minValueRange === maxValueRange) {
            // It means the range is in same category
            if (!arr?.[minValueRange]) {
                arr[minValueRange] = [];
            }
            // Adding a value to the maximum value as the bar height is not much visibnle in case of
            // data?.min_input_aqi and data?.max_input_aqi is equal or the difference is 1 or 2
            let addionFactior = 0;
            if (data?.max_input_aqi - data?.min_input_aqi) {
                addionFactior = 3;
            }
            arr[minValueRange].push({
                x,
                y: [parseFloat(data?.min_input_aqi), parseFloat(data?.max_input_aqi) + addionFactior],
            });
        } else {
            // It means the range is not in same category
            let startheight = data?.min_input_aqi;
            let leftoverheight = range[minValueRange]?.aqi_high;
            for (let index = minValueRange; index <= maxValueRange; index++) {
                if (!arr?.[index]) {
                    arr[index] = [];
                }
                arr[index].push({ x, y: [startheight, leftoverheight] });
                if (leftoverheight === data?.max_input_aqi) {
                    break;
                }
                if (barHeight <= range[index + 1]?.aqi_high) {
                    leftoverheight = data?.max_input_aqi;
                } else {
                    leftoverheight = range[index + 1]?.aqi_high;
                }
                startheight = range[index]?.aqi_high;
            }
        }
    });
    const dataSets: any = [];
    const scales: ScaleType = {};
    const labels: any = getTimeIntervalValue(selectedTime);
    // Border radius is 0 because we have an inconsistent dataset and adding the borderSkipped in
    // any of the case will lead to inconsistent border radius i.e. making it flat bar
    arr.map((a: any, index: number) => {
        dataSets.push({
            data: a,
            fill: false,
            borderColor: colors?.[index],
            pointHoverBackgroundColor: colors?.[index],
            hoverBackgroundColor: colors?.[index],
            hoverBorderColor: colors?.[index],
            backgroundColor: colors?.[index],
            pointRadius: 1,
            borderWidth: 1,
            barThickness: 4,
            borderRadius: 0,
        });
    });

    scales['y'] = {
        type: 'linear',
        beginAtZero: true,
        display: true,
        min: 0,
        max: max,
        grid: {
            display: true,
            borderColor: getBorder1Value(theme),
            color: getBorder1Value(theme),
            borderDash: [2],
            drawOnChartArea: true,
        },
        ticks: {
            color: theme === Theme.DARK ? '#FFFFFF' : '#000',
            callback: function (this: Scale, value: any) {
                return this.getLabelForValue(Number(value));
            },
            autoSkip: false,
        },
        title: {
            display: true,
            text: ytitle,
            color: theme === Theme.DARK ? '#FFFFFF' : '#000',
        },
        symbol: '',
        position: 'left',
    };

    return { labels, datasets: dataSets, scales: scales };
};

/*This function returns a new array which does not contians the pollutant symbol provided from parameters */
function filterAndAddValue(newSelectedValues: MappedGraphValues[], val: MappedGraphValues, filterSymbol: string) {
    const filteredValues = newSelectedValues.filter((value: any) => value.symbol !== filterSymbol);
    return [...filteredValues, val];
}

export const getSelectedValuesBySelectedUnits = (val: any, selectedValues: any) => {
    const newSelectedValues = [...selectedValues];
    const selectedValueIndex = selectedValues.findIndex((value: any) => value.title === val?.title);

    if (selectedValueIndex >= 0) {
        newSelectedValues.splice(selectedValueIndex, 1);

        return newSelectedValues;
    } else {
        // If user has not selected 2 values
        if (newSelectedValues.length <= 1) {
            return [...newSelectedValues, val];
        }

        /*Check if selectedvalue has symbol ug/m3 or not , if yes and the incoming value is also same symbol than push in the list or if the incoming value is not ug/m3 but the list only contains ug/m3 then let the user add in the same list */
        const selectedCommonValue = newSelectedValues.filter((value: any) => value.symbol === microGramPerUnit);
        const selectedMillionPerUnitValue = newSelectedValues.filter((value: any) => value.symbol === millionPerUnit);

        if (
            selectedCommonValue.length === newSelectedValues.length ||
            (selectedCommonValue.length > 0 && val.symbol === microGramPerUnit)
        ) {
            return [...newSelectedValues, val];
        }
        /*Check if selectedvalue has symbol millions/m3 or not , if yes and the incoming value is also same symbol than push in the list or if the incoming value is not millions/m3 but the list only contains millions/m3 then let the user add in the same list */
        if (
            selectedMillionPerUnitValue.length === newSelectedValues.length ||
            (selectedMillionPerUnitValue.length > 0 && val.symbol === millionPerUnit)
        ) {
            return [...newSelectedValues, val];
        }
        // if the incoming value is not ug/m3 but the list also contains values other than the ug/m3
        // then call filterAndAddValue function which will remove all values containing first symbol from selected data

        if (val.symbol !== microGramPerUnit && selectedCommonValue.length > 0 && newSelectedValues.length >= 1) {
            return filterAndAddValue(newSelectedValues, val, newSelectedValues[0]?.symbol);
        }
        // if the incoming value is not millions/m3 but the list also contains values other than the millions/m3
        // then call filterAndAddValue function which  will remove all values containing first symbol from selected data
        if (val.symbol !== millionPerUnit && selectedMillionPerUnitValue.length > 0 && newSelectedValues.length >= 1) {
            return filterAndAddValue(newSelectedValues, val, newSelectedValues[0]?.symbol);
        }
        if (newSelectedValues.length >= 2) {
            newSelectedValues.splice(0, 1);
        }
        return [...newSelectedValues, val];
    }
};

export const getColorByStatus = (status: string) => {
    switch (status) {
        case 'ideal':
            return StatusEnumColors.IDEAL;
        case 'moderate':
            return StatusEnumColors.MODERATE;
        case 'healthy':
            return StatusEnumColors.HEALTHY;
        case 'hazardous':
            return StatusEnumColors.HAZARDOUS;
        default:
            return StatusEnumColors.IDEAL;
    }
};

export const getColorNameByRangeAndValueAQI = (range: Array<AqiRange>, value: number | undefined) => {
    return value !== undefined
        ? range.filter((r: AqiRange) => value <= r.aqi_high && value >= r.aqi_low)?.[0]?.aqi_name.toLowerCase()
        : '';
};

export const getColorNameByRangeAndValuePollutant = (range: Array<PollutantRange>, value: number, key: string) => {
    const keyRanges = range.filter((range: PollutantRange) => range.pollutant_name === key);
    const keyRangesValue = keyRanges.find((range: PollutantRange) => {
        if (value <= range.bp_high && value >= range.bp_low) {
            return range.aqi_name;
        }
    });
    return keyRangesValue ? keyRangesValue.aqi_name : '-';
};

export const getColorNameByRangeAndValueVirusRisk = (range: Array<VirusAndProductivityRange>, value: number) => {
    return value
        ? range
              .filter((r: VirusAndProductivityRange) => value <= r.risk_high && value >= r.risk_low)?.[0]
              ?.risk_name.toLowerCase()
        : '';
};
export const getRoutesByCustomerRole = (roleid: USER_ROLE_ID) => {
    switch (roleid) {
        case USER_ROLE_ID.OrganizationAdmin:
            return routes;
        case USER_ROLE_ID.HeadOfOperations:
            return [DashboardRoute, ManageSiteRoute];
        case USER_ROLE_ID.FacilityManager:
            return routes;
        case USER_ROLE_ID.ChiefSustainabilityOfficer:
            return [DashboardRoute, ManageSiteRoute];
        default:
            return [];
    }
};

export const getSideBarByCustomerRole = (roleid: USER_ROLE_ID) => {
    switch (roleid) {
        case USER_ROLE_ID.OrganizationAdmin:
            return [...navigation, ...servicer_nav];
        case USER_ROLE_ID.HeadOfOperations:
            return [DashboardNav, SiteNav];
        case USER_ROLE_ID.FacilityManager:
            return [...navigation, ...servicer_nav];
        case USER_ROLE_ID.ChiefSustainabilityOfficer:
            return [DashboardNav, SiteNav];
        default:
            return [];
    }
};

export const getFileByName = (value: Performance_Benefits) => {
    switch (value) {
        case Performance_Benefits.RISKLEVELPRODUCTIVITY:
            return require('assets/icons/icon_images/risk_productivity.png');
        case Performance_Benefits.RISKVIRUS:
            return require('assets/icons/icon_images/risk_disease.png');
        case Performance_Benefits.POLLUTANT:
            return require('assets/icons/icon_images/pollutant.png');
        case Performance_Benefits.AQI:
            return require('assets/icons/icon_images/aqi.png');
    }
};

/**
 * Function sorts the data with current month to last given maxNumberOfData of months
 * @param data
 * @param maxNumberOfData
 */
export const getSortedData = (data: BarData[], maxNumberOfData = 6) => {
    let current_year = moment().year().toString();
    const values: BarData[] = [];
    for (let index = 0; index <= 1; index++) {
        const year_date_data = data.filter((bar_data: any) => bar_data.average_year === current_year);
        const year_date_data_sorted = year_date_data?.sort((a: any, b: any) => b.average_month - a.average_month);

        year_date_data_sorted && values.push(...year_date_data_sorted);

        if (values.length < maxNumberOfData) {
            current_year = moment().subtract(1, 'year').year().toString();
        } else {
            break;
        }
    }
    return values.slice(0, maxNumberOfData).reverse();
};

export const createGraphDownloadCSVData = (
    graphData: IDASHBOARDGRAPH,
    t: any,
    selectedValues?: Array<any>,
    multiple = 10,
    isFehreneit = false,
    selectedBenefit = Performance_Benefits.POLLUTANT,
) => {
    if (selectedBenefit === Performance_Benefits.AQI) {
        const newGraphData = graphData as IDASHBOARDAQIVALUE[];
        return newGraphData.map((d: IDASHBOARDAQIVALUE) => {
            return {
                time: moment(moment.utc(d.binned_time).local().valueOf()).format(DATETIME_FORMAT),
                'Average IAQ': Math.round(parseFloat(d.avg_input_aqi)),
                'Max IAQ': Math.round(parseFloat(d.max_input_aqi)),
                'Min IAQ': Math.round(parseFloat(d.min_input_aqi)),
            };
        });
    } else if (selectedBenefit === Performance_Benefits.POLLUTANT) {
        const newGraphData = graphData as PollutantValues[];
        return newGraphData.map((d: any) => {
            const obj: any = {
                time: moment(moment.utc(d.binned_time).local().valueOf()).format(DATETIME_FORMAT),
            };
            selectedValues?.map((value: any) => {
                const val = Math.round(parseFloat(d[value['mappedValue']]));
                if (value['title'] === RoomComform.TEMPERATURE.title && isFehreneit) {
                    obj[value['title'] + ' ' + PollutantSymbolByName.DEGREE_FAHRENHEIT] = Math.round(convertCToF(val));
                } else {
                    obj[value['title'] + ' ' + (value['title'] !== AQI.title ? value?.symbol : '')] = val;
                }
            });
            return obj;
        });
    } else {
        const newGraphData = graphData as IDASHBOARDVALUE[];
        return newGraphData.map((d: IDASHBOARDVALUE) => {
            return {
                time: moment(moment.utc(d.binned_time).local().valueOf()).format(DATETIME_FORMAT),
                [t(selectedBenefit)]: Math.round(parseFloat(d.value) * multiple),
            };
        });
    }
};

export const convertCToF = (cValue: number) => {
    return (cValue * 9) / 5 + 32;
};

export const getMaxTvoc = (PolluantRange: PollutantRange[]) => {
    return PolluantRange?.filter((range: PollutantRange) => range?.pollutant_name === PollutantEnum.TVOC)?.sort(
        (a: PollutantRange, b: PollutantRange) => b?.bp_high - a?.bp_high,
    )?.[0]?.bp_high;
};

export const checkForSpecialCharacter = (value: string) =>
    NotAllowedSpecialCharacters.some((val: string) => value.includes(val));

export const getSpecialCharacterMessage = (t: any) =>
    t('special_characters') + ' ( ' + NotAllowedSpecialCharacters?.toString() + ' ) ' + t('not_allowed') + '.';

export const sortAqiRange = (range?: AqiRange[]): AqiRange[] => {
    return range && range?.length > 0 ? range?.sort((a: AqiRange, b: AqiRange) => a?.aqi_high - b?.aqi_high) : [];
};

export const sortVirusOrProductivityRange = (range?: VirusAndProductivityRange[]): VirusAndProductivityRange[] => {
    return range && range?.length > 0
        ? range?.sort((a: VirusAndProductivityRange, b: VirusAndProductivityRange) => a?.risk_high - b?.risk_high)
        : [];
};

export const getChildDeviceCommand = (childNodes: RemoteCommandsTableData[]) => {
    return childNodes.reduce((previousValue: RemoteCommandsTableThingList[], currentValue: any) => {
        if (currentValue?.thing_list && Array.isArray(currentValue.thing_list) && currentValue.thing_list.length > 0) {
            previousValue.push(...currentValue.thing_list);
        }
        return previousValue;
    }, []);
};

export const getChildNodesDeviceCommand = (localNodes: RemoteCommandsTableData[], childIds: Array<number>) => {
    return localNodes?.filter((node: RemoteCommandsTableData) =>
        childIds.some((childid: number) => node.id === childid),
    );
};
export const getAvailableAndInstalledDevicesDeviceCommand = (
    arr: RemoteCommandsTableThingList[],
): IAvaibaleInstalled => {
    return arr.reduce(
        (preValue: IAvaibaleInstalled, currentDeviceValue: any) => {
            if (currentDeviceValue?.attributes?.location === SiteLocationEnum.AtSiteInstalled) {
                preValue.installed.push(currentDeviceValue);
            } else if (currentDeviceValue?.attributes?.location === SiteLocationEnum.AtSite) {
                preValue.available.push(currentDeviceValue);
            }
            return preValue;
        },
        {
            available: [],
            installed: [],
        },
    );
};

/*Function accets a string and convert first character to uppercase */
export const convertoToTitleCase = (str: string) => {
    return str
        ?.toLowerCase()
        ?.split(' ')
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
};

/*This function checks the site has any device installed or not if any the hasInstalledDevices key will be added and set to true */
export const addNewKeyToNode = (childNodes: RemoteCommandsTableData[], devicedata: RemoteCommandsTableData) => {
    childNodes.unshift(devicedata);
    childNodes.forEach((site: RemoteCommandsTableData) => {
        const thingList = site?.thing_list;
        site.hasInstalledDevices = thingList?.some(
            (thing: RemoteCommandsTableThingList) => thing?.attributes?.location === SiteLocationEnum.AtSiteInstalled,
        );
    });
    childNodes.forEach((site: RemoteCommandsTableData) => {
        if (!site.hasInstalledDevices) {
            site.hasInstalledDevices = false;
        }
    });
    childNodes?.map((siteData: RemoteCommandsTableData) => {
        siteData?.thing_list?.map((thingData: RemoteCommandsTableThingList) => {
            if (thingData?.cmd_data) {
                const plannedAirCleaningData = updatePlannedAirCleaning(thingData?.cmd_data?.PlannedAirCleaning);
                const manualAirCleaningData = updateManualAirCleaning(thingData?.cmd_data?.ManualAirCleaning);
                thingData.cmd_data.PlannedAirCleaning = plannedAirCleaningData;
                thingData.cmd_data.ManualAirCleaning = manualAirCleaningData;
            }
        });
    });
    return childNodes;
};

/*This function accepts all sites and deviceId of selected node and returns all data of selected node or site */
export const getParticularDeviceData = (deviceData: RemoteCommandsTableData[], deviceId: any) => {
    return deviceData?.find((item: RemoteCommandsTableData) => item?.id == deviceId);
};

/*This function will accept Total devices and selected devices data and return command Data for selected devices*/
export const getDeviceData = (childSiteNames: RemoteCommandsTableData[], deviceSerialNo: string[]) => {
    const matchingThingList = [];

    for (const item of childSiteNames) {
        if (item.hasOwnProperty(ThingData.THING_LIST)) {
            for (const thingItem of item.thing_list) {
                if (thingItem.hasOwnProperty(ThingData.THING_NAME) && deviceSerialNo.includes(thingItem.thingName)) {
                    matchingThingList.push(thingItem);
                }
            }
        }
    }
    return matchingThingList;
};

/*This function will accept a Rangevalue and returns scss class for color change based on Range value.  */

export const LabelColor = (Level: string) => {
    return LabelColorSet[Level] || LabelColorSet[RangeValues.MEDIUM];
};

export const LabelBackgroundColorDark = (Level: string) => {
    return AirCleaningLevelLabelColorSetDark[Level] || AirCleaningLevelLabelColorSetDark['Moderate'];
};
export const LabelBackgroundColorWhite = (Level: string) => {
    return AirCleaningLevelLabelColorSetWhite[Level] || AirCleaningLevelLabelColorSetWhite['Moderate'];
};

/*Here we will pass the device serial no and get that particular device model name and create new key value pair data with device_name and model_name*/
export const findModelName = (deviceName: string, childSiteNames: any) => {
    for (const devices of childSiteNames) {
        if (devices.thing_list) {
            for (const device of devices.thing_list) {
                if (device.attributes.device_name === deviceName || device?.thingName === deviceName) {
                    return {
                        device_name: deviceName,
                        model_name: device.attributes.model_name,
                    };
                }
            }
        }
    }
    return null;
};

/*This function will check the schedule of current time according to schedule data and return that level 
if not matches then it will return last schedule Level else return default */
export const getCurrentLevel = (data: ScheduledPlannedAirCleaingApiStructure[]) => {
    data?.sort((currentDaySchedule: any, nextDaySchedule: any) => currentDaySchedule?.Day - nextDaySchedule?.Day);
    const currentTime = moment();
    let currentLevel = null;
    const lastLevelData = data[data.length - 1];
    const lastLevel = lastLevelData[Schedulelevel.LEVEL];
    for (const entry of data) {
        const [hour, minute] = entry.Time.split(':');
        const entryTime = moment().day(entry.Day).hour(Number(hour)).minute(Number(minute));
        // Check if the current time is greater than or equal to the entry time
        if (currentTime.isSameOrAfter(entryTime)) {
            currentLevel = entry.Level;
        } else {
            // If the current time is before the entry time, break the loop
            break;
        }
    }
    // Return the last level found or a default level if none is found
    return currentLevel !== null ? currentLevel : lastLevel;
};

const updatePlannedAirCleaning = (
    plannedAirCleaningData: PlannedAirCleaningInterface | undefined,
): PlannedAirCleaningInterface => {
    const NoOfSchedules = plannedAirCleaningData?.NoOfSchedules;
    const schedules: PlannedAirCleaningSchedule[] = [];
    const schedlesDataInString: string[] = plannedAirCleaningData?.Schedules as unknown as string[];
    schedlesDataInString?.map((scheduleData) => {
        const scheduleSplitString: string[] = scheduleData?.split(',');
        if (scheduleSplitString?.length >= 5) {
            const plannedAirCleaningData: PlannedAirCleaningSchedule = {
                Day: dayMappingReverse[+scheduleSplitString[0]],
                Time: `${scheduleSplitString[1].padStart(2, '0')}:${scheduleSplitString[2].padStart(2, '0')}`,
                Level: getLevelFromAliasPlannedAirCleaning(scheduleSplitString[3]),
                DNR: scheduleSplitString[4] === '1',
            };
            schedules.push(plannedAirCleaningData);
        }
    });
    return { NoOfSchedules: NoOfSchedules as number, Schedules: schedules as unknown as Schedule };
};

const getLevelFromAlias = (alias: string) => {
    return rangeAliasMap[alias];
};
const getLevelFromAliasPlannedAirCleaning = (alias: string) => {
    return rangeAliasMapsReverse[alias];
};

export const getLevelAlias = (level: string) => {
    for (const key in rangeAliasMap) {
        if (rangeAliasMapsReverse[key] == level) {
            return key;
        }
    }
};

export const getOccurenceAlias = (occurrence: string) => {
    for (const key in occuranceAliasMap) {
        if (occuranceAliasMap[key] == occurrence) {
            return key;
        }
    }
};

const updateManualAirCleaning = (
    manualAirCleaningData: ManualAirCleaning | undefined,
): ManualAirCleaning | undefined => {
    if (manualAirCleaningData) {
        manualAirCleaningData.Level = getLevelFromAlias(manualAirCleaningData?.Level) as RangeValues;
    }
    return manualAirCleaningData;
};
export const generateText = (inputArray: Array<number | string>) => {
    const frequency = inputArray[0] === 'm' ? 'month' : 'week';
    const minutes = Number(inputArray[4]) < 10 ? `0${inputArray[4]}` : `${inputArray[4]}`;
    let outputText = `Every ${inputArray[1]} ${frequency} on `;

    if (frequency === 'month') {
        outputText += `${getOrdinalSuffix(Number(inputArray[2]))} at ${inputArray[3]}:${minutes}`;
    } else if (frequency === 'week') {
        outputText += `${daysOfWeekString[Number(inputArray[2])]} at ${inputArray[3]}:${minutes}`;
    }

    return outputText;
};

const getOrdinalSuffix = (n: number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export const addSiteHierarchyChips = (siteData: RemoteCommandsTableData[] | undefined) => {
    if (siteData) {
        const idMap = new Map();
        siteData?.forEach((item) => idMap.set(item.id, item));

        siteData?.forEach((item) => {
            let parent = item;
            let chip = item.site_name;

            while (parent?.parent_id !== parent?.id) {
                parent = idMap.get(parent?.parent_id);
                chip = parent?.site_name != undefined ? parent?.site_name + ' > ' + chip : chip;
            }

            item.siteHierarchyChip = chip;
            if (item?.thing_list) {
                item?.thing_list?.forEach((thing) => {
                    thing.siteHierarchyChip = chip;
                });
            }
        });

        return siteData;
    }
};
export const countDeliveredToCustomer = (data: NodeElement[]) => {
    let count = 0;
    data?.forEach((node) => {
        if (node?.data?.thing_list) {
            node?.data?.thing_list?.forEach((thing) => {
                if (thing?.attributes && thing?.attributes?.location === SiteLocationEnum.DeliveredToCustomer) {
                    count++;
                }
            });
        }
    });
    return count;
};
export const sumAreaServiced = (data: NodeElement[]) => {
    let sum = 0;
    data?.forEach((node) => {
        if (node?.data?.thing_list) {
            node?.data?.thing_list?.forEach((thing) => {
                if (
                    thing?.attributes &&
                    thing?.attributes?.location === 'AtSiteInstalled' &&
                    thing?.thingTypeName === 'AirPurifier' &&
                    thing?.attributes?.area_serviced_sq_feet
                ) {
                    sum += parseFloat(thing?.attributes?.area_serviced_sq_feet);
                }
            });
        }
    });
    return sum;
};

// utils/filterDevices.js

export const filterDevicesDropdown = (
    devices: SiteAllocatedDevices,
    searchValue: string,
    setFilteredData: (data: SiteAllocatedDevices) => void,
) => {
    const filteredAllocated: SiteAllocatedDevices = {};

    if (!searchValue) {
        setFilteredData(devices);
    } else {
        for (const key in devices) {
            if (devices.hasOwnProperty(key)) {
                const siteData = devices[key];
                const allocatedThingsList = siteData?.allocated_things_list;

                if (Array.isArray(allocatedThingsList) && allocatedThingsList.length > 0) {
                    const matchingThing = allocatedThingsList.find((item) => item?.thingName?.includes(searchValue));

                    if (matchingThing) {
                        filteredAllocated[key] = {
                            ...siteData,
                            allocated_things_list: [matchingThing],
                        };
                    }
                }
            }
        }
        setFilteredData(filteredAllocated);
    }
};
export const getDropdownPlaceholder = (deviceCount: number) => {
    if (deviceCount > 0) {
        if (deviceCount === 1) {
            return `${deviceCount} ${t('device_selected')}`;
        } else {
            return `${deviceCount} ${t('devices_selected')}`;
        }
    } else {
        return t('select_devices').toString();
    }
};
export const deviceAllocateSuccessMesaage = t('devices') + ' ' + t('allocated') + ' ' + t('successfully');
export const deviceUnAllocateSuccessMesaage = t('devices') + ' ' + t('unallocated') + ' ' + t('successfully');
export const deviceInstallSuccessMesaage = t('devices') + ' ' + t('installed') + ' ' + t('successfully');
export const deviceUninstallSuccessMesaage = t('devices') + ' ' + t('uninstall_in_Capital') + ' ' + t('successfully');
export const deviceUninstallWithUnallocateSuccessMesaage =
    t('devices') + ' ' + t('uninstall_and_unallocated') + ' ' + t('successfully');

export const setPlaceholderClass = (addDevice: INITIAL_STATE_TYPE, theme: Theme) => {
    if (addDevice.dropdownValues.length) {
        if (theme === Theme.DARK) {
            return 'selected-values-placeholder-dark-theme';
        } else {
            return 'selected-values-placeholder-light-theme';
        }
    } else {
        return '';
    }
};

export const sortDevicesByThingType = (thingsData: SiteAllocatedDevices) => {
    return Object.values(thingsData).flatMap(
        (siteData) =>
            (siteData.allocated_things_list = Object.values(thingsData)
                .flatMap((siteData) => siteData.allocated_things_list)
                .sort(sortFunction)),
    );
};
export const getTextColorForHighOccupancy = (level: string) => {
    return level === PlannedAirCleaningValues.HIGH ? 'white' : '';
};
export const getNextDay = (day: string): string => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayIndex = days.indexOf(day);
    if (dayIndex === -1) return '';
    const nextDayIndex = (dayIndex + 1) % days.length;
    return days[nextDayIndex];
};
