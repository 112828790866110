import { UserState, UserActions } from 'redux/types';
import { ORG_TYPES, TemperatureEnum, Theme, LocalStorageKey } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';
import { getLocalStorageValue, setLocalStorageValue } from './../../shared/service/CommonService';

const initialState: UserState = {
    user: undefined,
    isLoading: false,
    error: null,
    isUpdateLoading: false,
    updateError: null,
    isUpdateFetched: false,
    users: [],
    isUsersLoading: false,
    usersError: null,
    inviteUserFetched: false,
    inviteUserLoading: false,
    inviteUserError: null,
    userRoleFetched: true,
    userRoleLoading: false,
    isCustomer: true,
    theme: (getLocalStorageValue(LocalStorageKey.THEME) as Theme) || Theme.DARK,
    isTemperatureLoding: false,
    temperature: TemperatureEnum.FAHRENHEIT,
};

export const userReducer = (state = initialState, action: UserActions): UserState => {
    const commonType = () => {
        return { ...state, inviteUserLoading: true, inviteUserError: null, inviteUserFetched: false };
    };
    switch (action.type) {
        case actionTypes.USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case actionTypes.USER_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
                isCustomer: action.payload.org_type === ORG_TYPES.CUSTOMER,
                theme: action?.payload?.theme ? action?.payload?.theme : Theme.DARK,
                temperature: action?.payload?.temperature ? action.payload.temperature : TemperatureEnum.CELSIUS,
            };
        case actionTypes.USER_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error,
            };
        case actionTypes.USER_UPDATE_REQUEST:
            return {
                ...state,
                isUpdateLoading: true,
                updateError: null,
                isUpdateFetched: false,
            };
        case actionTypes.USER_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                isUpdateLoading: false,
                isUpdateFetched: true,
            };
        case actionTypes.USER_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                isUpdateLoading: false,
                updateError: action.payload.error,
                isUpdateFetched: true,
            };
        case actionTypes.ALL_USER_REQUEST:
            return {
                ...state,
                isUsersLoading: true,
                usersError: null,
            };
        case actionTypes.ALL_USER_REQUEST_SUCCESS:
            return {
                ...state,
                isUsersLoading: false,
                users: action.payload.users,
            };
        case actionTypes.ALL_USER_REQUEST_FAILURE:
            return {
                ...state,
                isUsersLoading: false,
                updateError: action.payload.error,
            };
        case actionTypes.INVITE_USER_REQUEST:
            return commonType();
        case actionTypes.EDIT_USER_REQUEST:
            return commonType();
        case actionTypes.RESEND_INVITE_REQUEST:
            return commonType();
        case actionTypes.DELETE_USER_REQUEST:
            return commonType();
        case actionTypes.INVITE_USER_REQUEST_SUCCESS:
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserFetched: true,
            };
        case actionTypes.GET_USER_ROLES:
            return {
                ...state,
                userRoleFetched: false,
                userRoleLoading: true,
                user: state.user ? { ...state.user, roles: [] } : undefined,
            };
        case actionTypes.GET_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRoleFetched: true,
                userRoleLoading: false,
                user: state.user ? { ...state.user, roles: action.payload.roles } : undefined,
            };
        case actionTypes.INVITE_USER_REQUEST_FAILURE:
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserFetched: true,
                inviteUserError: action.payload.error,
                userRoleFetched: true,
                userRoleLoading: false,
                user: state.user ? { ...state.user, roles: [] } : undefined,
            };
        case actionTypes.REMOVE_USER:
            return {
                ...state,
                user: undefined,
                theme: Theme.DARK,
            };
        case actionTypes.SWITCH_THEME:
            setLocalStorageValue(
                LocalStorageKey.THEME,
                action?.payload?.theme === Theme.DARK ? Theme.DARK : Theme.LIGHT,
            );
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.SWITCH_THEME_SUCCESS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.SWITCH_TEMPERATURE:
            return {
                ...state,
                isTemperatureLoding: true,
            };
        case actionTypes.SWITCH_TEMPERATURE_SUCCESS_FAILURE:
            return {
                ...state,
                isTemperatureLoding: false,
            };
        default:
            return {
                ...state,
            };
    }
};
