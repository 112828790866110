export const FETCH_SITE_HEIRARCHY = 'FETCH_SITE_HEIRARCHY';
export const FETCH_SITE_HEIRARCHY_SUCCESS = 'FETCH_SITE_HEIRARCHY_SUCCESS';
export const FETCH_SITE_HEIRARCHY_FAILURE = 'FETCH_SITE_HEIRARCHY_FAILURE';
export const ADD_NEW_NODE = 'ADD_NEW_NODE';
export const SET_SITE_FLOW = 'SET_SITE_FLOW';
export const ADD_NEW_SITE = 'ADD_NEW_SITE';
export const ADD_NEW_SITE_SUCCESS = 'ADD_NEW_SITE_SUCCESS';
export const ADD_NEW_SITE_FAILURE = 'ADD_NEW_SITE_FAILURE';
export const REMOVE_NEW_NODE = 'REMOVE_NEW_NODE';
export const REMOVE_SELECTED_NODE = 'REMOVE_SELECTED_NODE';
export const REMOVE_SITE = 'REMOVE_SITE';
export const SELECT_NODE = 'SELECT_NODE';
export const HIDE_SITE = 'HIDE_SITE';
export const SHOW_SITE = 'SHOW_SITE';
export const SET_SITE_SELECTED_DEVICE = 'SET_SITE_SELECTED_DEVICE';
export const REMOVE_SITE_SELECTED_DEVICE = 'REMOVE_SITE_SELECTED_DEVICE';
export const ADD_SITE_CLICKED = 'ADD_SITE_CLICKED';
export const NODE_CLICKED = 'NODE_CLICKED';
export const SET_ALLOCATE_CLICKED = 'SET_ALLOCATE_CLICKED';
export const FETCH_ALLOCATE_DEVICES = 'FETCH_ALLOCATE_DEVICES';
export const FETCH_ALLOCATE_DEVICES_SUCCESS = 'FETCH_ALLOCATE_DEVICES_SUCCESS';
export const FETCH_ALLOCATE_DEVICES_FAILURE = 'FETCH_ALLOCATE_DEVICES_FAILURE';
export const ALLOCATE_DEVICE = 'ALLOCATE_DEVICE';
export const ALLOCATE_DEVICE_SUCCESS = 'ALLOCATE_DEVICE_SUCCESS';
export const ALLOCATE_DEVICE_FAILURE = 'ALLOCATE_DEVICE_FAILURE';
export const UN_ALLOCATE_DEVICE = 'UN_ALLOCATE_DEVICE';
export const UN_ALLOCATE_DEVICE_SUCCESS = 'UN_ALLOCATE_DEVICE_SUCCESS';
export const UN_ALLOCATE_DEVICE_FAILURE = 'UN_ALLOCATE_DEVICE_FAILURE';
export const SET_INSTALLED_CLICKED = 'SET_INSTALLED_CLICKED';
export const FETCH_INSTALL_DEVICES = 'FETCH_INSTALL_DEVICES';
export const FETCH_INSTALL_DEVICES_SUCCESS = 'FETCH_INSTALL_DEVICES_SUCCESS';
export const FETCH_INSTALL_DEVICES_FAILURE = 'FETCH_INSTALL_DEVICES_FAILURE';
export const INSTALL_DEVICE = 'INSTALL_DEVICE';
export const INSTALL_DEVICE_SUCCESS = 'INSTALL_DEVICE_SUCCESS';
export const INSTALL_DEVICE_FAILURE = 'INSTALL_DEVICE_FAILURE';
export const UNINSTALL_DEVICE = 'UNINSTALL_DEVICE';
export const UNINSTALL_DEVICE_SUCCESS = 'UNINSTALL_DEVICE_SUCCESS';
export const UNINSTALL_DEVICE_FAILURE = 'UNINSTALL_DEVICE_FAILURE';
export const FETCH_SITE_RHS = 'FETCH_SITE_RHS';
export const FETCH_SITE_RHS_SUCCESS = 'FETCH_SITE_RHS_SUCCESS';
export const FETCH_SITE_RHS_FAILURE = 'FETCH_SITE_RHS_FAILURE';
