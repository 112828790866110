export const SET_SELECTED_ORG_FILTER = 'SET_SELECTED_ORG_FILTER';
export const REMOVE_SELECTED_ORG_FILTER = 'REMOVE_SELECTED_ORG_FILTER';
export const SET_SELECTED_LIFECYCLE_STATE = 'SET_SELECTED_LIFECYCLE_STATE';
export const REMOVE_SELECTED_LIFECYCLE_STATE = 'REMOVE_SELECTED_LIFECYCLE_STATE';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const REMOVE_SELECTED_LOCATION = 'REMOVE_SELECTED_LOCATION';
export const SET_SELECTED_AQI_KEY = 'SET_SELECTED_AQI_KEY';
export const SET_SELECTED_AQI_KEY_VALUE = 'SET_SELECTED_AQI_KEY_VALUE';
export const REMOVE_SELECTED_AQI_KEY_VALUE = 'REMOVE_SELECTED_AQI_KEY_VALUE';
export const SET_SELECTED_AQI_CATEGORY = 'SET_SELECTED_AQI_CATEGORY';
export const REMOVE_SELECTED_AQI_CATEGORY = 'REMOVE_SELECTED_AQI_CATEGORY';
export const SAVE_FILTER = 'SAVE_FILTER';
export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const REMOVE_DEVICE_STATUS = 'REMOVE_DEVICE_STATUS';
export const RESET_DEVICE_FILTER = 'RESET_DEVICE_FILTER';
export const SET_OLD_DEVICE_FILTER = 'SET_OLD_DEVICE_FILTER';
export const SET_DEVICE_TAB = 'SET_DEVICE_TAB';
export const SET_ALERT_TYPE = 'SET_ALERT_TYPE';
export const REMOVE_ALERT_TYPE = 'REMOVE_ALERT_TYPE';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const REMOVE_DEVICE_TYPE = 'REMOVE_DEVICE_TYPE';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const REMOVE_SELECTED_MODEL = 'REMOVE_SELECTED_MODEL';
export const CLEAR_SELECTION_CLICKED_FROM_PANEL = 'CLEAR_SELECTION_CLICKED_FROM_PANEL';
export const CANCEL_CLICKED = 'CANCEL_CLICKED';
export const IS_ANY_FILTER_SELECTED = 'IS_ANY_FILTER_SELECTED';
export const IS_ALERT_OR_IAQ_FILTER_SELECTED = 'IS_ALERT_OR_IAQ_FILTER_SELECTED';
export const IS_APPLY_CLICK = 'IS_APPLY_CLICK';
export const IS_FILTER_APPLIED_FROM_DASHBOARD = 'IS_FILTER_APPLIED_FROM_DASHBOARD';
