import { DeviceFilterActions, DeviceFilterState } from 'redux/types/deviceFilterActionModel';
import { DeviceTabs, OUTPUT } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';

const initialState: DeviceFilterState = {
    selectedOrg: undefined,
    selectedLifecycleState: undefined,
    selectedLocation: undefined,
    deviceStateValue: undefined,
    input_aqi: undefined,
    output_aqi: undefined,
    input_aqi_category: undefined,
    output_aqi_category: undefined,
    deviceStatus: undefined,
    oldDevceFilterState: undefined,
    selectedDeviceTab: DeviceTabs.INSTALLED,
    selectedAlert: undefined,
    selectedDeviceType: undefined,
    selectedModel: undefined,
    clearSelectionClicked: false,
    cancelClicked: false,
    isAnyFilterSelected: false,
    isAlertOrAqiFlterSelected: false,
    isApplyClick: false,
    isFilterAppliedFromDashboard: false,
};

export const deviceFilterReducer = (state = initialState, action: DeviceFilterActions): DeviceFilterState => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_ORG_FILTER:
            return { ...state, selectedOrg: action.payload ?? undefined };
        case actionTypes.REMOVE_SELECTED_ORG_FILTER:
            return { ...state, selectedOrg: undefined };
        case actionTypes.SET_SELECTED_LOCATION:
            return { ...state, selectedLocation: action.payload };
        case actionTypes.REMOVE_SELECTED_LOCATION:
            return { ...state, selectedLocation: undefined };
        case actionTypes.SET_SELECTED_LIFECYCLE_STATE:
            return { ...state, selectedLifecycleState: action.payload };
        case actionTypes.REMOVE_SELECTED_LIFECYCLE_STATE:
            return { ...state, selectedLifecycleState: undefined };
        case actionTypes.SET_DEVICE_STATUS:
            return { ...state, deviceStateValue: action.payload };
        case actionTypes.REMOVE_DEVICE_STATUS:
            return { ...state, deviceStateValue: undefined };
        case actionTypes.SET_SELECTED_AQI_KEY_VALUE:
            return updateAqiKeyValue(state, action.payload);
        case actionTypes.SET_SELECTED_AQI_KEY:
            return updateAqiKey(state, action.payload);
        case actionTypes.REMOVE_SELECTED_AQI_KEY_VALUE:
            return removeAqiKeyValue(state, action.payload);
        case actionTypes.SET_SELECTED_AQI_CATEGORY:
            return updateAqiCategory(state, action.payload);
        case actionTypes.REMOVE_SELECTED_AQI_CATEGORY:
            return removeAqiCategory(state, action.payload);
        case actionTypes.RESET_DEVICE_FILTER:
            return {
                ...state,
                ...initialState,
                selectedDeviceTab: state.selectedDeviceTab,
                isApplyClick: state.isApplyClick,
                oldDevceFilterState: state.oldDevceFilterState,
            };
        case actionTypes.SET_OLD_DEVICE_FILTER:
            return {
                ...state,
                ...state.oldDevceFilterState,
                oldDevceFilterState: state.oldDevceFilterState,
            };
        case actionTypes.SAVE_FILTER:
            return {
                ...state,
                oldDevceFilterState: { ...state, oldDevceFilterState: state.oldDevceFilterState },
            };
        case actionTypes.SET_DEVICE_TAB:
            return {
                ...state,
                selectedDeviceTab: action.payload,
            };
        case actionTypes.SET_ALERT_TYPE:
            return {
                ...state,
                selectedAlert: action.payload,
            };
        case actionTypes.REMOVE_ALERT_TYPE:
            return {
                ...state,
                selectedAlert: undefined,
            };
        case actionTypes.SET_DEVICE_TYPE:
            return {
                ...state,
                selectedDeviceType: action.payload,
            };
        case actionTypes.REMOVE_DEVICE_TYPE:
            return {
                ...state,
                selectedDeviceType: undefined,
            };
        case actionTypes.SET_SELECTED_MODEL:
            return {
                ...state,
                selectedModel: action.payload,
            };
        case actionTypes.REMOVE_SELECTED_MODEL:
            return {
                ...state,
                selectedModel: undefined,
            };
        case actionTypes.CLEAR_SELECTION_CLICKED_FROM_PANEL:
            return {
                ...state,
                ...initialState,
                oldDevceFilterState: state.oldDevceFilterState,
                clearSelectionClicked: true,
                selectedDeviceTab: state.selectedDeviceTab,
            };
        case actionTypes.CANCEL_CLICKED:
            return {
                ...state,
                ...state.oldDevceFilterState,
                oldDevceFilterState: state.oldDevceFilterState,
                cancelClicked: true,
                clearSelectionClicked: false,
            };
        case actionTypes.IS_ANY_FILTER_SELECTED:
            return {
                ...state,
                isAnyFilterSelected: action.payload,
            };
        case actionTypes.IS_ALERT_OR_IAQ_FILTER_SELECTED:
            return {
                ...state,
                isAlertOrAqiFlterSelected: action.payload,
            };
        case actionTypes.IS_APPLY_CLICK:
            return {
                ...state,
                isApplyClick: action.payload,
            };
        case actionTypes.IS_FILTER_APPLIED_FROM_DASHBOARD:
            return {
                ...state,
                isFilterAppliedFromDashboard: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
const updateAqiKeyValue = (state: DeviceFilterState, payload: any) => {
    if (payload.type === OUTPUT) {
        return {
            ...state,
            output_aqi: { key: state.output_aqi?.key, value: payload.value },
            output_aqi_category: undefined,
        };
    } else {
        return {
            ...state,
            input_aqi: { key: state.input_aqi?.key, value: payload.value },
            input_aqi_category: undefined,
        };
    }
};

const updateAqiKey = (state: DeviceFilterState, payload: any) => {
    if (payload.type === OUTPUT) {
        return {
            ...state,
            output_aqi: { value: state.output_aqi?.value, key: payload.key },
            output_aqi_category: undefined,
        };
    } else {
        return {
            ...state,
            input_aqi: { value: state.input_aqi?.value, key: payload.key },
            input_aqi_category: undefined,
        };
    }
};

const removeAqiKeyValue = (state: DeviceFilterState, payload: any) => {
    if (payload.type === OUTPUT) {
        return {
            ...state,
            output_aqi: undefined,
        };
    } else {
        return {
            ...state,
            input_aqi: undefined,
        };
    }
};

const updateAqiCategory = (state: DeviceFilterState, payload: any) => {
    if (payload.type === OUTPUT) {
        return {
            ...state,
            output_aqi_category: payload.value,
            output_aqi: undefined,
        };
    } else {
        return {
            ...state,
            input_aqi_category: payload.value,
            input_aqi: undefined,
        };
    }
};

const removeAqiCategory = (state: DeviceFilterState, payload: any) => {
    if (payload.type === OUTPUT) {
        return {
            ...state,
            output_aqi_category: undefined,
        };
    } else {
        return {
            ...state,
            input_aqi_category: undefined,
        };
    }
};
