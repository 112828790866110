import { DeviceActions, DeviceState } from 'redux/types';
import * as actionTypes from '../actionTypes';

const initialState: DeviceState = {
    isLoading: false,
    things: [],
    isFetched: false,
    error: undefined,
    next_token: undefined,
    count: 0,
    isSingleDeviceFetched: false,
    isSingleDeviceLoading: false,
    thing: undefined,
    metalmarkThings: [],
    singleDeviceError: undefined,
    lastQuery: '',
    graphResponse: undefined,
    graphLoading: false,
    graphError: undefined,
};

export const deviceReducer = (state = initialState, action: DeviceActions): DeviceState => {
    switch (action.type) {
        case actionTypes.DEVICE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                error: undefined,
                things: action.payload.isMetalmarkQuery ? state.things : [],
                lastQuery: action.payload.save_last_query ? action.payload.query_string : state.lastQuery,
                metalmarkThings: action.payload.isMetalmarkQuery ? [] : state.metalmarkThings,
            };
        case actionTypes.DEVICE_REQUEST_APPEND:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                error: undefined,
            };
        case actionTypes.DEVICE_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                things: action.payload.isMetalmarkQuery ? state.things : action.payload.things,
                next_token: action.payload.nextToken,
                isFetched: true,
                error: undefined,
                count: action.payload.count,
                metalmarkThings: action.payload.isMetalmarkQuery ? action.payload.things : state.metalmarkThings,
            };
        case actionTypes.DEVICE_REQUEST_APPEND_SUCCESS:
            return {
                ...state,
                isLoading: false,
                things: [...state.things, ...action.payload.things],
                next_token: action.payload.nextToken,
                isFetched: true,
                error: undefined,
            };
        case actionTypes.DEVICE_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                things: [],
                error: action.payload.error,
                isFetched: true,
            };
        case actionTypes.SINGLE_DEVICE_REQUEST:
            return {
                ...state,
                isSingleDeviceLoading: true,
                isSingleDeviceFetched: false,
                singleDeviceError: undefined,
            };
        case actionTypes.SINGLE_DEVICE_REQUEST_SUCCESS:
            return {
                ...state,
                isSingleDeviceLoading: false,
                isSingleDeviceFetched: true,
                singleDeviceError: undefined,
                thing: action.payload.thing,
            };
        case actionTypes.SINGLE_DEVICE_REQUEST_FAILURE:
            return {
                ...state,
                isSingleDeviceLoading: false,
                thing: undefined,
                singleDeviceError: action.payload.error,
                isSingleDeviceFetched: true,
            };
        case actionTypes.SINGLE_DEVICE_GRAPH_REQUEST:
            return {
                ...state,
                graphLoading: true,
                graphError: undefined,
                graphResponse: undefined,
            };
        case actionTypes.SINGLE_DEVICE_GRAPH_REQUEST_SUCCESS:
            return {
                ...state,
                graphLoading: false,
                graphError: undefined,
                graphResponse: action.payload.graphResponse,
            };
        case actionTypes.SINGLE_DEVICE_GRAPH_REQUEST_FAILURE:
            return {
                ...state,
                graphLoading: false,
                graphError: action.payload.error,
            };
        case actionTypes.GET_ALL_DEVICES:
            return {
                ...state,
                isLoading: true,
                isFetched: false,
                error: undefined,
            }
        case actionTypes.GET_ALL_DEVICES_SUCESS:
            return {
                ...state,
                isLoading: false,
                isFetched: true,
                error: undefined,
                count: action.payload.count,
                things: action.payload.things,
            }
        case actionTypes.GET_ALL_DEVICES_FAILURE:
            return {
                ...state,
                isLoading: false,
                things: [],
                error: action.payload.error,
                count: 0,
                isFetched: true,
            };
        default:
            return {
                ...state,
            };
    }
};
