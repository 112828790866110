import { AuthActions, AuthState } from 'redux/types/authActionModel';
import { setLocalStorageValue } from 'shared/service/CommonService';
import { LocalStorageKey } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';

const initialState: AuthState = {
    accessToken: '',
    refreshToken: '',
    isLoading: false,
    error: null,
    roleId: 0,
};

export const authReducer = (state = initialState, action: AuthActions): AuthState => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case actionTypes.LOGIN_REQUEST_SUCCESS: {
            const updatedState = {
                ...state,
                isLoading: false,
                accessToken: action.payload.access_token,
                refreshToken: action.payload.refresh_token,
            };
            setLocalStorageValue(LocalStorageKey.ACCESS_TOKEN, updatedState.accessToken.toString());
            setLocalStorageValue(LocalStorageKey.REFRESH_TOKEN, updatedState.refreshToken.toString());
            return updatedState;
        }
        case actionTypes.LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error,
            };
        case actionTypes.REMOVE_TOKENS:
            return initialState;
        default:
            return {
                ...state,
            };
    }
};
