import { onLoginAPI } from 'api/AuthAPI';
import { all, call, Effect, put, takeLatest } from 'redux-saga/effects';
import {
    fetchFilterRequest,
    fetchRangesRequest,
    loginRequestFailure,
    loginRequestSuccess,
    userRequest,
} from 'redux/actions';
import { LoginResponse } from 'redux/types/authActionModel';
import * as actionTypes from '../actionTypes';

/*
  Worker Saga: Fired on LOGIN_REQUEST action
*/
function* fetchLoginSaga(action: Effect) {
    try {
        const response: LoginResponse = yield call(onLoginAPI, action.payload);
        yield put(loginRequestSuccess(response));
        yield put(userRequest());
        yield put(fetchRangesRequest());
        yield put(fetchFilterRequest());
    } catch (e: any) {
        yield put(
            loginRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `LOGIN_REQUEST` action.
*/
function* authSaga() {
    yield all([takeLatest(actionTypes.LOGIN_REQUEST, fetchLoginSaga)]);
}

export default authSaga;
