import { SidebarActions, SidebarState } from 'redux/types/sidebarActionModel';
import * as actionTypes from '../actionTypes';

const initialState: SidebarState = {
    togglerShown: false,
    rhsPanelShow: false,
    lhsPanelShow: true,
};

const sidebarReducer = (state = initialState, action: SidebarActions): SidebarState => {
    switch (action.type) {
        case actionTypes.SHOW_HIDE_LHS_PANEL:
            return { ...state, lhsPanelShow: action.payload.show };
        case actionTypes.SHOW_RHS_PANEL_TOGGLER:
            return { ...state, togglerShown: action.payload.show };
        case actionTypes.SHOW_HIDE_RHSPANEL:
            return { ...state, rhsPanelShow: action.payload.show };
        default:
            return state;
    }
};

export default sidebarReducer;
