import { DeviceActions } from 'redux/types/deviceCommandModel';
import * as actionTypes from '../actionTypes';
import {
    addNewKeyToNode,
    addSiteHierarchyChips,
    getAvailableAndInstalledDevicesDeviceCommand,
    getChildDeviceCommand,
    getChildNodesDeviceCommand,
    getParticularDeviceData,
} from 'shared/service/CommonService';
import { getChildId } from 'shared/service/NodeService';
import { DeviceCommandsState } from '../types/deviceCommandModel';

const initialState: DeviceCommandsState = {
    deviceData: [],
    particularDeviceData: [],
    FloorName: '',
    childSiteNames: [],
    devicesrno: [],
    devicemodedata: undefined,
    deviceconfirmationdata: [],
    isLoading: false,
    isResponseSuccess: false,
    showButonPanel: false,
    isError: false,
    isButton: false,
    isCheckbox: false,
    errorMessage: '',
    checkboxDiv: [],
    checkboxRow: [],
    tableLoading: true,
    deviceId: undefined,
    deviceCount: undefined,
    isButtonClicked: false,
    searchChips: [],
};

export const DeviceCommandReducer = (state = initialState, action: DeviceActions): DeviceCommandsState => {
    switch (action.type) {
        case actionTypes.ADD_DEVICE_SERIAL_NO:
            return {
                ...state,
                devicesrno: action.payload.data,
            };
        case actionTypes.CLEAR_CHECKBOX:
            return {
                ...state,
                isCheckbox: action.payload.data,
            };
        case actionTypes.ADD_DIV_VALUE:
            return {
                ...state,
                checkboxDiv: action.payload.data,
            };
        case actionTypes.ADD_ROW_VALUE:
            return {
                ...state,
                checkboxRow: action.payload.data,
            };
        case actionTypes.CHANGE_ISLOADING_STATE:
            return {
                ...state,
                devicemodedata: undefined,
                isLoading: false,
                showButonPanel: false,
                deviceconfirmationdata: undefined,
                isError: false,
            };
        case actionTypes.CLEAR_ISLOADING_STATE:
            return {
                ...state,
                showButonPanel: false,
                isLoading: false,
            };
        case actionTypes.CHANGE_BUTTON_STATE:
            return {
                ...state,
                isButton: action.payload.data,
            };
        case actionTypes.ADD_SCHEDULE:
            return {
                ...state,
                isButtonClicked: action.payload.data,
            };
        case actionTypes.CHANGE_DEVICE_MODE:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.CHANGE_DEVICE_MODE_SUCCESS: {
            const deviceLength = action?.payload?.data?.devicemodedata?.device_command_id_dict;
            return {
                ...state,
                devicemodedata: action?.payload?.data,
                isLoading: true,
                showButonPanel: deviceLength.length === 0,
            };
        }
        case actionTypes.CHANGE_DEVICE_MODE_FAILURE:
            return {
                ...state,
                isError: true,
            };
        case actionTypes.CHANGE_DEVICE_MODE_CONFIRMATION_SUCCESS:
            return {
                ...state,
                deviceconfirmationdata: action?.payload?.data,
                isResponseSuccess: action?.payload?.data?.islastcall,
                showButonPanel: action?.payload?.data?.islastcall,
            };
        case actionTypes.CHANGE_DEVICE_MODE_CONFIRMATION_FAILURE:
            return {
                ...state,
                isError: true,
                showButonPanel: false,
            };
        case actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA:
            return {
                ...state,
                tableLoading: true,
            };
        case actionTypes.SET_DEVICE_ID:
            return {
                ...state,
                deviceId: action?.payload?.data,
            };
        case actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA_SUCCESS: {
            const arryChilds = getChildId(action?.payload?.data?.data, state?.deviceId, []);
            const childNodes = getChildNodesDeviceCommand(action.payload?.data?.data, arryChilds);

            const devicedata = getParticularDeviceData(action?.payload?.data?.data, state?.deviceId);
            const childDevices = getChildDeviceCommand(childNodes);
            /*Calculating the childsite data*/
            const childSiteData = childDevices && getAvailableAndInstalledDevicesDeviceCommand(childDevices);
            const thinglistdata = devicedata?.thing_list;
            /*calculating the parent site data*/
            const parentSiteData = thinglistdata && getAvailableAndInstalledDevicesDeviceCommand(thinglistdata);
            /*calculating the devicecount(total installed devices on site) of selected site*/
            const deviceCount =
                (childSiteData != undefined ? childSiteData?.installed?.length : 0) +
                (parentSiteData != undefined ? parentSiteData?.installed?.length : 0);
            const updatedChildSite = devicedata && addNewKeyToNode(childNodes, devicedata);
            const siteDataWithChips = addSiteHierarchyChips(updatedChildSite);
            return {
                ...state,
                FloorName: devicedata?.site_name,
                childSiteNames: siteDataWithChips,
                tableLoading: false,
                deviceCount: deviceCount,
            };
        }
        case actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA_FAILURE:
            return {
                ...state,
                childSiteNames: undefined,
                tableLoading: false,
            };
        case actionTypes.SET_SELECTED_SEARCHED:
            return {
                ...state,
                searchChips: state?.searchChips?.concat(action.payload),
            };
        case actionTypes.REMOVE_SELECTED_SEARCHED:
            return {
                ...state,
                searchChips: state?.searchChips?.filter((chip) => chip !== action.payload),
            };
        case actionTypes.REMOVE_ALL_SEARCHED:
            return {
                ...state,
                searchChips: [],
            };
        case actionTypes.TEST_ACTION_2:
            return {
                ...state,
                deviceData: [],
            };
        default:
            return state;
    }
};
