export const ADD_DEVICE_SERIAL_NO = 'ADD_DEVICE_SERIAL_NO';
export const CLEAR_CHECKBOX = 'CLEAR_CHECKBOX';
export const ADD_DIV_VALUE = 'ADD_DIV_VALUE';
export const ADD_ROW_VALUE = 'ADD_ROW_VALUE';
export const CHANGE_DEVICE_MODE = 'CHANGE_DEVICE_MODE';
export const CHANGE_DEVICE_MODE_SUCCESS = 'CHANGE_DEVICE_MODE_SUCCESS';
export const CHANGE_DEVICE_MODE_FAILURE = 'CHANGE_DEVICE_MODE_FAILURE';
export const CHANGE_DEVICE_MODE_CONFIRMATION_SUCCESS = 'CHANGE_DEVICE_MODE_CONFIRMATION_SUCCESS';
export const CHANGE_DEVICE_MODE_CONFIRMATION_FAILURE = 'CHANGE_DEVICE_MODE_CONFIRMATION_FAILURE';
export const DEVICE_MODE_FAILURE = 'DEVICE_MODE_FAILURE';
export const CHANGE_ISLOADING_STATE = 'CHANGE_ISLOADING_STATE';
export const CLEAR_ISLOADING_STATE = 'CLEAR_ISLOADING_STATE';
export const CHANGE_BUTTON_STATE = 'CHANGE_BUTTON_STATE';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const FETCH_REMOTE_COMMANDS_TABLE_DATA = 'FETCH_REMOTE_COMMANDS_TABLE_DATA';
export const FETCH_REMOTE_COMMANDS_TABLE_DATA_SUCCESS = 'FETCH_REMOTE_COMMANDS_TABLE_DATA_SUCCESS';
export const FETCH_REMOTE_COMMANDS_TABLE_DATA_FAILURE = 'FETCH_REMOTE_COMMANDS_TABLE_DATA_FAILURE';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_SELECTED_SEARCHED = 'SET_SELECTED_SEARCHED';
export const REMOVE_SELECTED_SEARCHED = 'REMOVE_SELECTED_SEARCHED';
export const REMOVE_ALL_SEARCHED = 'REMOVE_ALL_SEARCHED';
export const TEST_ACTION_2 = 'TEST_ACTION_2';
