import { IFilter } from 'shared/model';
import * as actionTypes from '../actionTypes';
import {
    FailurePayload,
    FetchFilterRequest,
    FetchFilterRequestSuccess,
    FetchFilterRequestFailure,
    FetchRangesRequest,
    FetchRangesRequestSuccess,
    FetchRangesRequestFailure,
    FetchRangesRequestSuccessRespose,
} from '../types';

export const fetchFilterRequest = (): FetchFilterRequest => ({
    type: actionTypes.FETCH_FILTER_VALUE,
});

export const fetchFilterRequestSuccess = (payload: IFilter): FetchFilterRequestSuccess => ({
    type: actionTypes.FETCH_FILTER_VALUE_SUCCESS,
    payload,
});

export const fetchFilterRequestFailure = (payload: FailurePayload): FetchFilterRequestFailure => ({
    type: actionTypes.FETCH_FILTER_VALUE_FAILURE,
    payload,
});

export const fetchRangesRequest = (): FetchRangesRequest => ({
    type: actionTypes.FETCH_RANGES_VALUE,
});

export const fetchRangesRequestSuccess = (payload: FetchRangesRequestSuccessRespose): FetchRangesRequestSuccess => ({
    type: actionTypes.FETCH_RANGES_VALUE_SUCCESS,
    payload,
});

export const fetchRangesRequestFailure = (payload: FailurePayload): FetchRangesRequestFailure => ({
    type: actionTypes.FETCH_RANGES_VALUE_FAILURE,
    payload,
});
