import { OrganizationModel, RolesI, UserModel, UserPwdModel, UsersModel, UserUpdateModel } from 'shared/model';
import { ORG_TYPES, TemperatureEnum, Theme } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';
import {
    AllUserRequest,
    AllUserRequestFailure,
    AllUserRequestSuccess,
    ChangeTemperaturePreference,
    DeleleteUserRequest,
    EditUserRequest,
    FailurePayload,
    GetUserRolesRequest,
    GetUserRolesRequestFailure,
    GetUserRolesRequestSuccess,
    InviteUserRequest,
    InviteUserRequestFailure,
    InviteUserRequestSuccess,
    ResendInviteRequest,
    SwitchTheme,
    UserRequest,
    UserRequestFailure,
    UserRequestSuccess,
    UserUpdateRequest,
    UserUpdateRequestFailure,
    UserUpdateRequestSuccess,
} from '../types';

export const userRequest = (): UserRequest => ({
    type: actionTypes.USER_REQUEST,
});

export const userRequestSuccess = (payload: UserModel): UserRequestSuccess => ({
    type: actionTypes.USER_REQUEST_SUCCESS,
    payload,
});

export const userRequestFailure = (payload: FailurePayload): UserRequestFailure => ({
    type: actionTypes.USER_REQUEST_FAILURE,
    payload,
});

export const userUpdateRequestAction = (payload: UserUpdateModel | UserPwdModel): UserUpdateRequest => ({
    type: actionTypes.USER_UPDATE_REQUEST,
    payload,
});

export const userUpdateRequestSuccessAction = (): UserUpdateRequestSuccess => ({
    type: actionTypes.USER_UPDATE_REQUEST_SUCCESS,
});

export const userUpdateRequestFailureAction = (payload: FailurePayload): UserUpdateRequestFailure => ({
    type: actionTypes.USER_UPDATE_REQUEST_FAILURE,
    payload,
});

export const getAllUsersAction = (): AllUserRequest => ({
    type: actionTypes.ALL_USER_REQUEST,
});

export const getAllUsersSuccessAction = (payload: {
    org_details: OrganizationModel;
    users: UsersModel[];
}): AllUserRequestSuccess => ({
    type: actionTypes.ALL_USER_REQUEST_SUCCESS,
    payload,
});

export const getAllUsersFaiureActionn = (payload: FailurePayload): AllUserRequestFailure => ({
    type: actionTypes.ALL_USER_REQUEST_FAILURE,
    payload,
});

export const inviteUserAction = (payload: { email: string; role_id: number; role: string }): InviteUserRequest => ({
    type: actionTypes.INVITE_USER_REQUEST,
    payload,
});

export const inviteUserSuccessAction = (): InviteUserRequestSuccess => ({
    type: actionTypes.INVITE_USER_REQUEST_SUCCESS,
});

export const inviteUserFailureAction = (payload: FailurePayload): InviteUserRequestFailure => ({
    type: actionTypes.INVITE_USER_REQUEST_FAILURE,
    payload,
});

export const editUserAction = (payload: {
    email?: string;
    role?: string;
    id: number;
    role_id?: number;
}): EditUserRequest => ({
    type: actionTypes.EDIT_USER_REQUEST,
    payload,
});

export const resendInviteUserAction = (payload: { id: number; is_org?: boolean }): ResendInviteRequest => ({
    type: actionTypes.RESEND_INVITE_REQUEST,
    payload,
});

export const deleteUserAction = (id: number): DeleleteUserRequest => ({
    type: actionTypes.DELETE_USER_REQUEST,
    id,
});

export const getUserRolesAction = (org_type: ORG_TYPES): GetUserRolesRequest => ({
    type: actionTypes.GET_USER_ROLES,
    payload: { org_type },
});

export const getUserRolessuccessAction = (roles: RolesI[]): GetUserRolesRequestSuccess => ({
    type: actionTypes.GET_USER_ROLES_SUCCESS,
    payload: { roles },
});

export const getUserRolesFailureAction = (payload: FailurePayload): GetUserRolesRequestFailure => ({
    type: actionTypes.GET_USER_ROLES_FAILURE,
    payload,
});

export const swtichThemeAction = (theme: Theme): SwitchTheme => ({
    type: actionTypes.SWITCH_THEME,
    payload: { theme },
});

export const switchTemperaturePrefrence = (temperature: TemperatureEnum): ChangeTemperaturePreference => ({
    type: actionTypes.SWITCH_TEMPERATURE,
    payload: { temperature },
});
