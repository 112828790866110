import { UserPwdModel, UserUpdateModel } from 'shared/model';
import { ORG_TYPES, TemperatureEnum, Theme, URL_Type } from 'shared/utils/Constants';
import { http_delete, http_get, http_post, http_put } from './BaseURLAxios';
import {
    ACTIVATE_INVITE_USER_URL,
    INVITE_USER_URL,
    RESEND_INVITE_URL,
    USERS_URL,
    USER_ROLES_URL,
    USER_PREFERENCE,
    USER_URL,
    VALIDATE_INVITE_USER_URL,
} from './urlConstants';

export const getUserAPI = async () => {
    return http_get(USER_URL).then((res: any) => {
        return res;
    });
};

export const updateUserAPI = async (body: UserUpdateModel | UserPwdModel) => {
    return http_put(USER_URL, body).then((res: any) => {
        return res;
    });
};

export const getAllUsersAPI = async () => {
    return http_get(USERS_URL).then((res: any) => {
        return res;
    });
};

export const inviteNewUserAPI = async (body: { email: string; role_id: number }) => {
    return http_post(INVITE_USER_URL, body).then((res: any) => {
        return res;
    });
};

export const validateInviteUserAPI = async (body: { url: string; type: URL_Type }) => {
    return http_post(VALIDATE_INVITE_USER_URL, body).then((res: any) => {
        return res;
    });
};

export const activatePasswordInviteUserAPI = async (
    body: { url: string; password: string; type: URL_Type },
    token: string,
) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    return http_post(ACTIVATE_INVITE_USER_URL, body, config).then((res: any) => {
        return res;
    });
};

export const editUserAPI = async (body: { email?: string; role?: string; id?: number }) => {
    const id = body.id;
    delete body?.id;
    return http_put(USER_URL + '/' + id, body).then((res: any) => {
        return res;
    });
};

export const resendInviteAPI = async (body: { id: number; is_org: boolean }) => {
    return http_post(RESEND_INVITE_URL, body).then((res: any) => {
        return res;
    });
};

export const deleteUserAPI = async (id: number) => {
    return http_delete(USER_URL + '/' + id).then((res: any) => {
        return res;
    });
};

export const getUserRoles = async (org_type: ORG_TYPES) => {
    return http_get(USER_ROLES_URL + '?org_type=' + org_type).then((res: any) => {
        return res;
    });
};

export const swithUserTheme = async (theme: Theme) => {
    return http_get(USER_PREFERENCE + '?theme=' + theme).then((res: any) => {
        return res;
    });
};

export const swithTemperaturePreference = async (temp: TemperatureEnum) => {
    return http_get(USER_PREFERENCE + '?temp=' + temp).then((res: any) => {
        return res;
    });
};
