import { all, fork } from 'redux-saga/effects';
import authSaga from './authSaga';
import organizationSaga from './orgSaga';
import dashboardSaga from './dashboardSaga';
import deviceCommandSaga from './deviceCommandSaga';
import userSaga from './userSaga';
import deviceSaga from './deviceSaga';
import sharedSaga from './sharedSaga';
import siteHeirarchySaga from './siteHeirarchySaga';
import firmwaresSaga from './firmwaresSaga';

export function* rootSaga() {
    yield all([
        fork(dashboardSaga),
        fork(organizationSaga),
        fork(authSaga),
        fork(userSaga),
        fork(deviceSaga),
        fork(sharedSaga),
        fork(siteHeirarchySaga),
        fork(deviceCommandSaga),
        fork(firmwaresSaga),
    ]);
}
