import { combineReducers } from 'redux';
import { dashboardReducer } from './dashboardReducer';
import sidebarReducer from './sidebarReducer';
import { authReducer } from './authReducer';
import { toastStateReducer } from './toastReducer';
import { userReducer } from './userReducer';
import { orgReducer } from './orgReducer';
import { deviceReducer } from './deviceReducer';
import { sharedReducer } from './sharedReducer';
import { siteHeirarchyReducer } from './siteReducer';
import { deviceFilterReducer } from './deviceFilterReducer';
import { DeviceCommandReducer } from './deviceCommandReducer';
import { firmwaresReducer } from './firmwaresReducer';

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
    dashboardReducer: dashboardReducer,
    sidebarReducer: sidebarReducer,
    orgReducer: orgReducer,
    authReducer: authReducer,
    toastStateReducer: toastStateReducer,
    userReducer: userReducer,
    deviceReducer: deviceReducer,
    shared: sharedReducer,
    siteHeirarchy: siteHeirarchyReducer,
    deviceFilterReducer: deviceFilterReducer,
    DeviceCommandReducer: DeviceCommandReducer,
    firmwaresReducer: firmwaresReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
